import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiCallStatus, FormType, LoggedInStatus } from "../../utils/constants";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import AnnouncementCreateEdit from "../../components/AnnouncementCreateEdit";
import {
	checkIsLoggedIn,
	clearCookies,
	fetchCurrentUserData
} from "../PageFunctions/commonFunctions";
import {
	editAnnouncementData,
	fetchAnnouncementData,
} from "../PageFunctions/announcementFunctions";
import moment from "moment";

const AnnouncementEditPage = () => {
	const { announcementId } = useParams();
	const navigate = useNavigate();
	const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
	const [currentUser, setCurrentUser] = useState(null);
	const [announcementData, setAnnouncementData] = useState(null);

	const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchAnnouncementDataCallStatus, setFetchAnnouncementDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [editAnnouncementDataCallStatus, setEditAnnouncementDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const gotoAnnouncementsPage = () => navigate("/announcements");
	const reloadPage = () => navigate(0);

	const performLoggedInCheck = async () => {
		await checkIsLoggedIn({
			setLoggedIn
		});
	}

	const clearCookiesAsync = async () => {
		await clearCookies();
	}

	const fetchCurrentUserAsync = async () => {
		await fetchCurrentUserData({
			setCurrentUser,
			setCurrentUserCallStatus
		});
	}

	const fetchAnnouncementDataAsync = async () => {
		if (!!announcementId) {
			await fetchAnnouncementData({
				announcementId,
				setAnnouncementData,
				setFetchAnnouncementDataCallStatus
			});
		}
	}

	const editAnnouncementDataAsync = async ({
		announcementId,
		title,
		html,
		startDate,
		endDate,
		type,
		bannerImage
	}) => {
		await editAnnouncementData({
			announcementId,
			title,
			html,
			startDate,
			endDate,
			type,
			bannerImage,
			setEditAnnouncementDataCallStatus
		});
	}

	const authorizedPageLoad = async () => {
		await Promise.all([
			fetchCurrentUserAsync(),
			fetchAnnouncementDataAsync()
		]);
	}

	useEffect(() => {
		performLoggedInCheck();
	}, []);

	useEffect(() => {
		if (loggedIn === LoggedInStatus.Yes) {
			authorizedPageLoad();
		} else if (loggedIn === LoggedInStatus.Refreshed) {
			reloadPage();
		} else if (loggedIn === LoggedInStatus.No) {
			clearCookiesAsync();
			gotoHomePage();
		}
	}, [loggedIn]);

	const onSubmit = async (values) => {
		const timezoneOffset = new Date().getTimezoneOffset();
		const offsetHours = parseInt(Math.abs(timezoneOffset / 60));
		const offsetMinutes = Math.abs(timezoneOffset % 60);
		const offsetSign = timezoneOffset > 0 ? "-" : "+"; // Note that the sign is reversed (JS is "+", ISO is "-")
		const formattedOffset = offsetSign + String(offsetHours).padStart(2, '0') + ":" + String(offsetMinutes).padStart(2, '0');

		const startDateInput = moment(`${values.startDate} ${values.startTime}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DDTHH:mm:ss') + formattedOffset;
		const endDateInput = moment(`${values.endDate} ${values.endTime}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DDTHH:mm:ss') + formattedOffset;

		let response = await editAnnouncementDataAsync({
			announcementId,
			html: values.html,
			bannerImage: values.bannerImage,
			startDate: startDateInput,
			endDate: endDateInput
		});

		if (!!response) {
			gotoAnnouncementsPage();
		}
	}

	const allLoadingStates = [
		currentUserCallStatus,
		fetchAnnouncementDataCallStatus,
		editAnnouncementDataCallStatus
	];

	const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

	return (
		<Fragment>
			<SiteHeader currentUser={currentUser}/>
			<AnnouncementCreateEdit
				formType={FormType.Edit}
				onBackClick={gotoAnnouncementsPage}
				announcement={announcementData}
				submit={onSubmit}/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={isLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default AnnouncementEditPage;