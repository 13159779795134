import { isLoggedIn, removeAuthCookies } from "../../api/authentication";
import { ApiCallStatus, LoggedInStatus } from "../../utils/constants";
import { getCurrentUser } from "../../api/authenticationController";
import { toast } from "react-toastify";
import { getCountries, getProvinces, getSalesPeople } from "../../api/orderController";

export const clearCookies = async () => {
	await removeAuthCookies();
};

export const checkIsLoggedIn = async ({
	setLoggedIn
}) => {
	try {
		const response = await isLoggedIn();
		setLoggedIn(response);
	} catch (e) {
		console.error(e);
		setLoggedIn(LoggedInStatus.No);
	}
}

export const fetchCurrentUserData = async ({
	setCurrentUser,
	setCurrentUserCallStatus
}) => {
	setCurrentUserCallStatus(ApiCallStatus.InProgress);
	try {
		const response = await getCurrentUser();
		if (response.success) {
			setCurrentUser(response.data);
			setCurrentUserCallStatus(ApiCallStatus.Succeeded);
		}
		else {
			setCurrentUserCallStatus(ApiCallStatus.Failed);
		}
	} catch (e) {
		console.error(e);
		setCurrentUserCallStatus(ApiCallStatus.Error)
	}
};

export const clearCookiesAsync = async () => {
	await clearCookies();
}

export const fetchProvinceData = async ({
	setProvinces,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getProvinces();

		if (!!response?.success) {
			setProvinces(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
			toast("Could not get province data.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		console.error(error)
		setStatusError();
		toast("Could not get province data.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const fetchCountryData = async ({
	setCountries,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getCountries();

		if (!!response?.success) {
			setCountries(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
			toast("Could not get country data.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		console.error(error)
		setStatusError();
		toast("Could not get country data.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const fetchSalesPeopleData = async ({
	setSalesPeople,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getSalesPeople();

		if (!!response?.success) {
			setSalesPeople(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
			toast("Could not get sales people data.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		console.error(error)
		setStatusError();
		toast("Could not get sales people data.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};