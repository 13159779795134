import React, { Fragment, useEffect, useState } from "react";
import { Button, Container, Grid, Icon, Label, Menu, Pagination, Popup, Table } from "semantic-ui-react";
import { ApiCallResult } from "../../utils/constants";
import { Link } from "react-router-dom";
import TecfinityAccountLinkModal from "../TecfinityAccountLinkModal";


const Users = ({
	users,
	userToEdit,
	tradeAccount,
	tecfinityAccount,
	showTecfinityAccountLinkModal,
	showTecfinityAccountDeLinkModal,
	showAccountActivationModal,
	showAccountDeactivationModal,
	openTecfinityAccountLinkModal,
	openTecfinityAccountDeLinkModal,
	openAccountActivationModal,
	openAccountDeactivationModal,
	handleTecfinityAccountLinkModalClose,
	closeModals,
	pageNumber,
	pageSize,
	setUserToEdit,
	setTradeAccount,
	totalResults,
	onBackClick,
	onPageNumberChange
}) => {

	const handleOpenTecfinityAccountLinkModalClick = user => {
		const accountToEdit = user.tradeAccounts[0];

		if (accountToEdit && user) {
			setUserToEdit(user);
			setTradeAccount(accountToEdit);
			openTecfinityAccountLinkModal();
		}
	}

	return (
		<div className="argus-min-height">
			<div className="argus-title-background mb-5">
                <div className="argus-title">Users</div>
            </div>
			{!!users &&
				<Grid container>
					<Grid.Row>
						<Grid.Column width={16}>
							<Button compact
									size="tiny"
									className="blue-basic-button borderless"
									onClick={onBackClick}>
								<Icon name="arrow left"/>
								Back
							</Button>

						</Grid.Column>
					</Grid.Row>
					<Grid.Row verticalAlign="middle" textAlign="center">
						<Grid.Column textAlign="center" width={16}>
							<Table sortable selectable compact textAlign="center">
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell className="sticky-top" rowSpan='1'>
											Name
										</Table.HeaderCell>
										<Table.HeaderCell className="sticky-top"
														  rowSpan='1'>
											Email
										</Table.HeaderCell>
										<Table.HeaderCell className="sticky-top"
														  rowSpan='1'>
											Mobile
										</Table.HeaderCell>
										<Table.HeaderCell className="sticky-top" rowSpan="1">
											Account type
										</Table.HeaderCell>
										<Table.HeaderCell className="sticky-top" rowSpan="2">
											Account numbers
										</Table.HeaderCell>
										<Table.HeaderCell className="sticky-top"
														  rowSpan="1">
											Active
										</Table.HeaderCell>
										<Table.HeaderCell className="sticky-top"
														  rowSpan="1">
											Actions
										</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{!!users && users.map((c, index) => {
										let actionRequired = false;

										if (c.tradeAccountRequested) actionRequired = true;
										const tradeAccountNumbers = c.tradeAccounts.map(t => t.accountNumber);

										return (
											<Table.Row key={index}>
												<Table.Cell>
													<Link
														to={`/user/${c.id}`}>{`${c.firstName} ${c.lastName}`}</Link>
												</Table.Cell>
												<Table.Cell>{c.email}</Table.Cell>
												<Table.Cell>{c.phoneNumber}</Table.Cell>
												<Table.Cell>
													{!!c.isTradeAccount &&
														<Label size="tiny"
															   className="table-blue-label borderless">
															Trade
														</Label>
													}
													{!c.isTradeAccount &&
														<Label size="tiny"
															   className="table-green-label borderless">
															Retail
														</Label>
													}
												</Table.Cell>
												<Table.Cell>
													<Label.Group size="tiny">
														{!!tradeAccountNumbers && tradeAccountNumbers.map(a => {
															return (
																<Label className="table-blue-label borderless">
																	{a}
																</Label>
															)
														})}
													  </Label.Group>

												</Table.Cell>
												<Table.Cell>
													<Icon name={`${c.isActive ? "check" : "times"}`}
														  color={`${c.isActive ? "green" : "red"}`}/>
												</Table.Cell>
												<Table.Cell textAlign="center">
													<Popup flowing
														   basic
														   hoverable
														   trigger={<Icon
															   color={actionRequired ? "red" : "black"}
															   name={actionRequired ? "exclamation circle" : "ellipsis vertical"}/>}>
														<Menu vertical className="argus-menu" borderless fluid>
															{!!c.tradeAccountRequested &&
																<Menu.Item to="#"
																		   as={Link}
																		   onClick={() => handleOpenTecfinityAccountLinkModalClick(c)}>
																	Link Tecfinity account
																</Menu.Item>
															}
														</Menu>
													</Popup>

												</Table.Cell>
											</Table.Row>
										)
									})}
								</Table.Body>
							</Table>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={16} textAlign="right">
							{totalResults && totalResults > 0 &&
								<Pagination
									activePage={pageNumber}
									boundaryRange={1}
									size="mini"
									onPageChange={onPageNumberChange}
									siblingRange={1}
									totalPages={Math.ceil(totalResults / pageSize)}
								/>
							}
						</Grid.Column>
					</Grid.Row>
				</Grid>

			}
			{showTecfinityAccountLinkModal &&
				<TecfinityAccountLinkModal
					open={showTecfinityAccountLinkModal}
					setOpen={openTecfinityAccountLinkModal}
					setClose={closeModals}
					handleModalClose={handleTecfinityAccountLinkModalClose}
					userToEdit={userToEdit}
					tradeAccount={tradeAccount}
				/>
			}
		</div>
	)
}

export default Users;