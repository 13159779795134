import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import { Button, Form, Grid, Header, Icon, Image } from "semantic-ui-react";
import ArgusLogo from "../../img/logo.svg";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { getTecfinityAccount } from "../../api/tecfinityController";
import { Link } from "react-router-dom";
import { putTradeAccount } from "../../api/userController";
import "./style.css";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minHeight: '50%',
	width: "25%",
	bgcolor: '#FFFFFF',
	boxShadow: 24,
	p: 4,
};

const modalState = {
	search: 1,
	searching: 2,
	accountFound: 3,
	accountNotFound: 4,
	linking: 5,
	accountLinked: 6,
	accountNotLinked: 7,
	error: 8
}

const getTecfinityAccountData = async ({
	setCurrentModalState,
	setTecfinityAccountFound,
	setModalError,
	accountNumber
}) => {
	setCurrentModalState(modalState.searching);
	try {
		const response = await getTecfinityAccount(accountNumber);
		if (response.success) {
			setTecfinityAccountFound(response.data);
			setCurrentModalState(modalState.accountFound);
		}
		else {
			setCurrentModalState(modalState.accountNotFound);
		}
	}
	catch (error) {
		setCurrentModalState(modalState.error);
		setModalError("Could not load Tecfinity information. Please try again later or contact support.");
		console.error(error)
	}
};

const editTradeAccount = async ({
	tradeAccountId,
	accountNumber,
	companyRegNo,
	personalIdNumber,
	setCurrentModalState
}) => {
	setCurrentModalState(modalState.linking);
	try {
		const editUserTecfinityAccountsOptions = {
			tradeAccountId,
			accountNumber,
			companyRegNo,
			personalIdNumber
		}

		const response = await putTradeAccount(editUserTecfinityAccountsOptions);

		if (!!response.success) {
			setCurrentModalState(modalState.accountLinked);
		}
		else {
			setCurrentModalState(modalState.accountNotLinked);
		}
	}
	catch (error) {
		setCurrentModalState(modalState.error);
		console.error(error);
	}
};

const TecfinityAccountLinkModal = ({
	open,
	setOpen,
	setClose,
	handleModalClose,
	userToEdit,
	tradeAccount
}) => {
	const [currentModalState, setCurrentModalState] = useState(modalState.search);
	const [modalError, setModalError] = useState("");
	const [tecfinityAccountFound, setTecfinityAccountFound] = useState(null);

	const schema = yup.object({
		tecfinityAccount: yup.string().required("Enter the Tecfinity Account Number to search")
	}).required();
	const {control, watch, getValues, setValue, handleSubmit, formState: {errors}, setError, clearErrors} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			tecfinityAccount: ""
		}
	});

	useEffect(() => {
		if (!userToEdit || !tradeAccount) {
			setCurrentModalState(modalState.error);
			setModalError("Could not load user information. Please try again later or contact support.")
		}
	}, []);

	const submitForm = async values => {
		const tecfinitySearchOptions = {
			setCurrentModalState,
			setTecfinityAccountFound,
			setModalError,
			accountNumber: values.tecfinityAccount
		}

		await getTecfinityAccountData(tecfinitySearchOptions)
	};

	const resetModal = () => {
		setValue("tecfinityAccount", "");
		setCurrentModalState(modalState.search);
		setModalError("")
	}

	const closeModal = () => {
		resetModal();
		setClose();
	}

	const handleConfirmTecfinityAccountLink = async () => {
		const createLinkTecfinityAccountDataOptions = {
			tradeAccountId: tradeAccount.tradeAccountId,
			accountNumber: tecfinityAccountFound.accountNumber,
			companyRegNo: tradeAccount.companyRegNo,
			personalIdNumber: tradeAccount.personalIdNumber,
			setCurrentModalState
		}
		await editTradeAccount(createLinkTecfinityAccountDataOptions);
	}

	return (
		<Modal open={open}>
			<Box sx={style}>
				<Grid>
					<Grid.Row textAlign="center">
						<Grid.Column width={16} textAlign="center">
							<Image src={ArgusLogo} size="small" fluid centered/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={16} textAlign="center">
							<div className="argus-modal-title">Link Tecfinity Account</div>
						</Grid.Column>
					</Grid.Row>
					{(currentModalState === modalState.search || currentModalState === modalState.searching) &&
						<>
							<Grid.Row>
								<Grid.Column width={16}>
									<div className="argus-modal-text">
										Enter the Tecfinity number that you would like to link to this account and hit
										confirm to create the link.
									</div>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16}>
									<Form onSubmit={handleSubmit(submitForm)} size="small">
										<Grid padded>
											<Grid.Row>
												<Grid.Column width={16}>
													<Controller
														name="tecfinityAccount"
														control={control}
														render={({field: {onChange, value}}) => (
															<Form.Input
																label="Tecfinity Account"
																type="text"
																onChange={onChange}
																value={value}
																disabled={currentModalState === modalState.searching}
																loading={currentModalState === modalState.searching}
																placeholder="Enter the Tecfinity account to search for..."/>
														)}
													/>
													<ErrorMessage
														errors={errors}
														name="tecfinityAccount"
														render={({message}) => <p
															className="b4 color-red mb-2">{message}</p>}
													/>
												</Grid.Column>
											</Grid.Row>
											<Grid.Row>
												<Grid.Column width={16} textAlign="right">
													<Button type="submit"
															floated="right"
															className="blue-button"
															disabled={currentModalState === modalState.searching}
															loading={currentModalState === modalState.searching}>
														Submit
													</Button>
													<Button type="button"
															floated="right"
															disabled={currentModalState === modalState.searching}
															loading={currentModalState === modalState.searching}
															onClick={closeModal}>
														Cancel
													</Button>
												</Grid.Column>
											</Grid.Row>
										</Grid>
									</Form>
								</Grid.Column>
							</Grid.Row>
						</>
					}
					{(currentModalState === modalState.accountFound || currentModalState === modalState.linking) && !!tecfinityAccountFound &&
						<>
							<Grid.Row>
								<Grid.Column width={16}>
									<div className="argus-modal-text">
										The Tecfinity account you searched for was found. These are the details of the
										accounts that will be linked:
									</div>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row width={16} verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<p className="b1"><b><span className="underline">Argus account</span></b></p>
									{!!tradeAccount.companyRegNo &&
										<p><b>Company Registration Number / Trading
											Name: </b> &nbsp; {tradeAccount.companyRegNo}</p>
									}
									{!!tradeAccount.personalIdNumber &&
										<p><b>ID Number: </b> &nbsp; {tradeAccount.personalIdNumber}</p>
									}
									<p><b>Contact: </b> &nbsp; {`${userToEdit.firstName} ${userToEdit.lastName}`}</p>
									<p><b>Email: </b> &nbsp; {userToEdit.email ?? "N/A"}</p>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row width={16} verticalAlign="middle" textAlign="center">
								<Grid.Column>
									<Icon name="linkify" size="large"/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row width={16} verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<p className="b1"><b><span className="underline">Tecfinity account</span></b></p>
									<p><b>Name:</b> &nbsp; {tecfinityAccountFound.name ?? "N/A"}</p>
									<p><b>Contact:</b> &nbsp; {tecfinityAccountFound.contact ?? "N/A"}</p>
									<p><b>Email:</b> &nbsp; {tecfinityAccountFound.email ?? "N/A"}</p>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16} textAlign="right">
									<Link className="argus-modal-link" to="#" onClick={resetModal}>
										Search again
									</Link>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16} textAlign="right">
									<Button type="button"
											className="blue-button"
											floated='right'
											disabled={currentModalState === modalState.linking}
											loading={currentModalState === modalState.linking}
											onClick={handleConfirmTecfinityAccountLink}>
										Link
									</Button>
									<Button type="button"
											floated="right"
											disabled={currentModalState === modalState.linking}
											loading={currentModalState === modalState.linking}
											onClick={closeModal}>
										Cancel
									</Button>
								</Grid.Column>
							</Grid.Row>
						</>
					}
					{currentModalState === modalState.accountLinked &&
						<>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Icon className="center-element"
										  name="check circle outline"
										  color="green"
										  size="massive"/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Header as="h1" textAlign="center">Account linked</Header>
								</Grid.Column>
								<Grid.Column width={16} textAlign="center">
									<p className="b1">Tecfinity account successfully linked.</p>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16} textAlign="right">
									<Button type="button"
											floated='right'
											onClick={closeModal}>
										Close
									</Button>
								</Grid.Column>
							</Grid.Row>
						</>
					}
					{currentModalState === modalState.accountNotFound &&
						<>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Icon className="center-element" name="times circle outline" color="red"
										  size="big"/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Header as="h1" textAlign="center">Account not found</Header>
								</Grid.Column>
								<Grid.Column width={16}>
									<Header as="h3" textAlign="center">
										{`Could not find Tecfinity account with account number ${getValues("tecfinityAccount")}`}
									</Header>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16} textAlign="right">
									<Button type="button"
											floated='right'
											onClick={closeModal}>
										Close
									</Button>
								</Grid.Column>
							</Grid.Row>
						</>
					}
					{currentModalState === modalState.error && !!modalError &&
						<>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Icon className="center-element" name="ban" color="red" size="massive"/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Header as="h1" textAlign="center">Error</Header>
								</Grid.Column>
								<Grid.Column width={16} textAlign="center">
									<p className="b1">{modalError}</p>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16} textAlign="right">
									<Button type="button"
											floated='right'
											onClick={closeModal}>
										Close
									</Button>
								</Grid.Column>
							</Grid.Row>
						</>
					}
				</Grid>
			</Box>
		</Modal>
	)
}

export default TecfinityAccountLinkModal;