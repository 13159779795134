import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../img/logo.svg";
import "./style.css";
import { Dropdown, Grid, Image, Menu } from "semantic-ui-react";

const SiteHeader = ({ currentUser }) => {
  const [currentPage, setCurrentPage] = useState(null);
  const location = useLocation();

  const pagesToExclude = ["login", "register", "account-verification", "forgot-password"];

  useEffect(() => {
    const pathName = location.pathname;
    const pageName = pathName.split("/").pop();
    setCurrentPage(pageName);
  }, [location]);

  return (
      <>
      {!pagesToExclude.includes(currentPage) &&
          <header className="header-container">
            <Grid padded>
              <Grid.Row className="mb-4" verticalAlign="middle">
                <Grid.Column width={3}>
                  <Image as={Link} to="/" src={Logo} alt="Argus" size="small"/>
                </Grid.Column>
                <Grid.Column width={7}>
                  <Menu className="argus-menu" borderless fluid>
                    <Menu.Menu position='left'>
                      {!!currentUser &&
                          <>
                            <Menu.Item
                                name='Users'
                                as={Link}
                                to="/users"
                            >
                              Users
                            </Menu.Item>
                            <Menu.Item
                                name='Orders'
                                as={Link}
                                to="/orders"
                            >
                              Orders
                            </Menu.Item>
                            <Dropdown item text='CMS'>
                              <Dropdown.Menu>
                                <Dropdown.Item as={Link}
                                               to="/announcements">Announcements</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </>
                      }
                    </Menu.Menu>
                  </Menu>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Menu className="argus-menu" borderless fluid>
                    <Menu.Menu position='right'>
                      {!currentUser &&
                          <>
                            <Menu.Item
                                name='Login'
                                as={Link}
                                to="/login"
                            >
                              Login
                            </Menu.Item>
                          </>
                      }
                      {!!currentUser &&
                          <>
                            <Dropdown item text='My Account'>
                              <Dropdown.Menu>
                                <Dropdown.Item as={Link}
                                               to="/logout">Sign out</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>

                          </>
                      }
                    </Menu.Menu>
                  </Menu>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </header>
      }
      </>
  );
};

export default SiteHeader;
