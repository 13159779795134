import React from "react";
import { Button, Container, Form, Grid, Header, Icon, Image, Segment } from "semantic-ui-react";
import ArgusLogo from "../../img/logo.svg";
import { Link } from "react-router-dom";
import { ApiCallResult } from "../../utils/constants";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ErrorMessage } from "@hookform/error-message";



const ForgotPassword = ({
	onSubmit,
	onResetClick,
	createForgotPasswordDataResult
}) => {
	const schema = yup.object({
		email: yup.string().email().required("Email is required")
	}).required();
	const {control, watch, getValues, setValue, handleSubmit, formState: {errors}, setError, clearErrors} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			email: ""
		}
	});

	const submitForm = async values => {
		await onSubmit({
			email: values.email
		});
	};

	return (
		<Container className="argus-full-page-container login-background" fluid>
			<Grid centered padded stretched verticalAlign="middle">
				<Grid.Row>
					<Grid.Column width={16}>
						<Image src={ArgusLogo} size="medium" centered as={Link} to="/"/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={2}/>
					<Grid.Column width={10}>
						<Segment padded>
							{createForgotPasswordDataResult === ApiCallResult.NoResult &&
								<Grid padded>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<Header as="h1">Forgot password</Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<p className="b2">
												Enter your email and we will send you instructions on
												how to reset your password.
											</p>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={2} />
										<Grid.Column width={12} textAlign="center">
											<Form onSubmit={handleSubmit(submitForm)} size="small">
												<Grid centered>
													<Grid.Row>
														<Grid.Column width={16} textAlign="center">
															<Controller
																name="email"
																control={control}
																render={({field: {onChange, value}}) => (
																	<Form.Input
																		label="Email"
																		onChange={onChange}
																		value={value}
																		placeholder="email@yourdomain.com"/>
																)}
															/>
															<ErrorMessage
																errors={errors}
																name="email"
																render={({message}) => <p
																	className="b4 color-red mb-2">{message}</p>}
															/>
														</Grid.Column>
													</Grid.Row>
													<Grid.Row>
														<Grid.Column width={16} textAlign="center">
															<Button className="blue-button"
																	type="submit">
																Submit
															</Button>
														</Grid.Column>
													</Grid.Row>
												</Grid>
											</Form>
										</Grid.Column>
										<Grid.Column width={2}/>
									</Grid.Row>
								</Grid>
							}
							{createForgotPasswordDataResult === ApiCallResult.Success &&
								<Grid padded>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<p className="b2">
												Password reset instructions have been sent to the email address you
												submitted. Please follow the instructions within the email to choose a new
												password.
											</p>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							}
							{createForgotPasswordDataResult === ApiCallResult.Error &&
								<Grid padded>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<p className="b2">
												Password reset instructions could not be sent. Please try again later.
											</p>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row verticalAlign="middle" textAlign="center">
										<Grid.Column width={16} textAlign="center">
											<Button type="button"
													className="blue-button"
													onClick={onResetClick}>
												Try again
											</Button>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							}
						</Segment>
					</Grid.Column>
					<Grid.Column width={2}/>
				</Grid.Row>
			</Grid>
		</Container>
	)
}

export default ForgotPassword;