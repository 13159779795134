import React from "react";
import { Route, Routes } from "react-router-dom";

import Homepage from "../HomePage";
import LoginPage from "../LoginPage";
import LogoutPage from "../LogoutPage";
import ForgotPasswordPage from "../ForgotPasswordPage";
import ResetPasswordPage from "../ResetPasswordPage";
import ErrorPage from "../ErrorPage";
import OrdersPage from "../OrdersPage";
import UsersPage from "../UsersPage";
import AnnouncementEditPage from "../AnnouncementEditPage";
import AnnouncementsPage from "../AnnouncementsPage";
import UserCreateEditPage from "../UserCreateEditPage";
import OrderCreateEditPage from "../OrderCreateEditPage";
import AnnouncementCreatePage from "../AnnouncementCreatePage";

const AllRoute = () => {
  return (
      <Routes>
          <Route exact path="/" element={<Homepage/>} />
          <Route path="/error" element={<ErrorPage/>} />
          <Route path="/login" element={<LoginPage/>} />
          <Route path="/forgot-password" element={<ForgotPasswordPage/>} />
          <Route path="/reset-password" element={<ResetPasswordPage/>} />
          <Route path="/logout" element={<LogoutPage/>} />
          <Route path="/orders" element={<OrdersPage/>} />
          <Route path="/order/:orderId" element={<OrderCreateEditPage/>} />
          <Route path="/users" element={<UsersPage/>} />
          <Route path="/user/:userId" element={<UserCreateEditPage/>} />
          <Route path="/announcements" element={<AnnouncementsPage/>} />
          <Route path="/announcement/create" element={<AnnouncementCreatePage/>} />
          <Route path="/announcement/:announcementId" element={<AnnouncementEditPage/>} />
          <Route element={<ErrorPage/>} />
      </Routes>
  );
};

export default AllRoute;
