import Cookies from "js-cookie";
import {storageKeys} from "./storageKeys";
import * as jose from "jose";
import {includes} from "lodash";
import {refresh} from "./authenticationController";
import { LoggedInStatus } from "../utils/constants";

export const isLoggedIn = async (removeCookies = true) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const secret = new TextEncoder().encode(process.env.REACT_APP_JWT_KEY);

        if (!token || !secret) {
            return await refresh();
        }
        const { payload} = await jose.jwtVerify(token, secret);
        const expiresAt = payload["exp"];
        const role = payload["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        const lowercaseRoles = role.toLowerCase();

        if (includes(lowercaseRoles, process.env.REACT_APP_ROLE_ARGUS_ADMIN.toLowerCase()) ||
            includes(lowercaseRoles, process.env.REACT_APP_ROLE_SERVER_ADMIN.toLowerCase())) {
            if ((Date.now() / 1000) < expiresAt) {
                const userId = payload["user_id"];
                Cookies.set(storageKeys.USER_ID, userId, { secure: true, sameSite: 'strict' });
            }
            return LoggedInStatus.Yes;
        }
        else {
            if (removeCookies) {
                await removeAuthCookies();
            }
            return LoggedInStatus.No;
        }
    } catch (e) {
        console.error(e);
        return await refresh();
    }
}

export const removeAuthCookies = async () => {
    await Cookies.remove(storageKeys.ACCESS_TOKEN);
    await Cookies.remove(storageKeys.REFRESH_TOKEN);
    await Cookies.remove(storageKeys.USER_ID);
}