import React, { Fragment } from "react";
import Logout from "../../components/Logout";

const LogoutPage = () => {

  return (
    <Fragment>
        <Logout />
    </Fragment>
  );
};
export default LogoutPage;
