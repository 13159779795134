import { ApiCallStatus } from "../../utils/constants";
import {
	activateAnnouncement,
	createAnnouncement,
	deactivateAnnouncement,
	editAnnouncement,
	getAnnouncement,
	getAnnouncementsOverview
} from "../../api/announcementController";
import { toast } from "react-toastify";
import { sortBy } from "lodash";
import moment from "moment";

export const createAnnouncementData = async ({
	title,
	html,
	bannerImage,
	startDate,
	endDate,
	type,
	setCreateAnnouncementDataCallStatus
}) => {
	setCreateAnnouncementDataCallStatus(ApiCallStatus.InProgress);
	try {
		const body = {
			websiteId: process.env.REACT_APP_PUBLIC_WEBSITE_ID,
			title,
			html,
			bannerImage,
			startDate,
			endDate,
			type
		};
		let response = await createAnnouncement(body);

		if (response.success) {
			setCreateAnnouncementDataCallStatus(ApiCallStatus.Succeeded);
			toast("Announcement created.", {
				type: "success",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			return response.data;
		} else {
			setCreateAnnouncementDataCallStatus(ApiCallStatus.Failed);
			toast(response.message, {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	} catch (error) {
		setCreateAnnouncementDataCallStatus(ApiCallStatus.Error);
		console.error(error);
		toast("Could not create announcement. Please try again later.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
}

export const editAnnouncementData = async ({
	announcementId,
	title,
	html,
	startDate,
	endDate,
	type,
	bannerImage,
	setEditAnnouncementDataCallStatus
}) => {
	setEditAnnouncementDataCallStatus(ApiCallStatus.InProgress);
	try {
		const body = {
			title,
			html,
			startDate,
			endDate,
			bannerImage,
			type
		};
		let response = await editAnnouncement(process.env.REACT_APP_PUBLIC_WEBSITE_ID, announcementId, body);

		if (response.success) {
			setEditAnnouncementDataCallStatus(ApiCallStatus.Succeeded);
			toast("Announcement edited.", {
				type: "success",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			return response.data;
		} else {
			setEditAnnouncementDataCallStatus(ApiCallStatus.Failed);
			toast(response.message, {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	} catch (error) {
		setEditAnnouncementDataCallStatus(ApiCallStatus.Error);
		console.error(error);
		toast("Could not create announcement. Please try again later.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
}

export const activateAnnouncementData = async ({
	announcementId,
	setInProgressStatus,
	setSuccessStatus,
	setFailedStatus,
	setErrorStatus,
	setErrorMessage
}) => {
	setInProgressStatus();
	try {
		let response = await activateAnnouncement(process.env.REACT_APP_PUBLIC_WEBSITE_ID, announcementId);

		if (response.success) {
			setSuccessStatus();
			toast("Announcement activated.", {
				type: "success",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			return response.data;
		} else {
			setFailedStatus();
			setErrorMessage(response.message);
		}
	} catch (error) {
		setErrorStatus();
		console.error(error);
		toast("Could not activate announcement. Please try again later.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
}

export const deactivateAnnouncementData = async ({
	announcementId,
	setInProgressStatus,
	setSuccessStatus,
	setFailedStatus,
	setErrorStatus,
	setErrorMessage
}) => {
	setInProgressStatus();
	try {
		let response = await deactivateAnnouncement(process.env.REACT_APP_PUBLIC_WEBSITE_ID, announcementId);

		if (response.success) {
			setSuccessStatus();
			toast("Announcement deactivated.", {
				type: "success",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
			return response.data;
		} else {
			setFailedStatus();
			setErrorMessage(response.message);
		}
	} catch (error) {
		setErrorStatus();
		console.error(error);
		toast("Could not deactivate announcement. Please try again later.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
}

export const fetchAnnouncementsData = async ({
	pageSize,
	pageNumber,
	setFetchAnnouncementsDataCallStatus,
	setAnnouncements,
	setTotalResults
}) => {
	setFetchAnnouncementsDataCallStatus(ApiCallStatus.InProgress);
	try {
		const response = await getAnnouncementsOverview({
			pageSize,
			pageNumber
		});

		if (response?.success) {
			const announcements = response.data;
			const total = response.data.total;
			const skip = response.data.skip;
			const limit = response.data.limit;
			const announcementsSorted = sortBy(announcements, x => moment(x.dateCreated).format("X"));

			setAnnouncements(announcementsSorted);
			setFetchAnnouncementsDataCallStatus(ApiCallStatus.Succeeded);
			setTotalResults(response.total);
		}
		else {
			setAnnouncements([]);
			setFetchAnnouncementsDataCallStatus(ApiCallStatus.Failed);
			setTotalResults(0);
		}
	}
	catch (error) {
		console.error(error)
		setFetchAnnouncementsDataCallStatus(ApiCallStatus.Error);
		toast("Could not get announcements. Please try again later.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const fetchAnnouncementData = async ({
	announcementId,
	setAnnouncementData,
	setFetchAnnouncementDataCallStatus
}) => {
	setFetchAnnouncementDataCallStatus(ApiCallStatus.InProgress);
	try {
		const response = await getAnnouncement(announcementId);

		if (response?.success) {
			const announcement = response.data;
			setAnnouncementData(announcement);
			setFetchAnnouncementDataCallStatus(ApiCallStatus.Succeeded);
		}
		else {
			console.error(response.message)
			setFetchAnnouncementDataCallStatus(ApiCallStatus.Error);
			toast("Could not get announcement. Please try again later.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {

	}
}