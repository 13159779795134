import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ApiCallStatus, LoggedInStatus } from "../../utils/constants";
import { Backdrop, CircularProgress } from "@mui/material";
import SiteHeader from "../../components/SiteHeader";
import Users from "../../components/Users";
import { fetchUsersData } from "../PageFunctions/userFunctions";
import { checkIsLoggedIn, clearCookies, fetchCurrentUserData } from "../PageFunctions/commonFunctions";

const ModalName = Object.freeze({
	TecfinityLink: Symbol(1),
	TecfinityDeLink: Symbol(2),
	AccountActivation: Symbol(3),
	AccountDeactivation: Symbol(4)
});

const UsersPage = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [loggedIn, setLoggedIn] = useState(null);
	const [currentUser, setCurrentUser] = useState(null);
	const [users, setUsers] = useState([]);
	const [tecfinityAccount, setTecfinityAccount] = useState(null);
	const [tradeAccount, setTradeAccount] = useState(null);
	const [userToEdit, setUserToEdit] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const [pageSize, setPageSize] = useState(18);

	const [openModal, setOpenModal] = useState(null);

	const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchUsersDataCallStatus, setFetchUsersDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const reloadPage = () => navigate(0);

	const fetchUsersDataAsync = async (pageNumberParam, pageSizeParam) => {
		await fetchUsersData({
			pageNumber: pageNumberParam,
			pageSize: pageSizeParam,
			setUsers,
			setFetchUsersDataCallStatus,
			setTotalResults
		});
	}

	const performLoggedInCheck = async () => {
		await checkIsLoggedIn({
			setLoggedIn
		});
	}

	const clearCookiesAsync = async () => {
		await clearCookies();
	}

	const fetchCurrentUserAsync = async () => {
		await fetchCurrentUserData({
			setCurrentUser,
			setCurrentUserCallStatus
		});
	}

	const authorizedPageLoad = async () => {
		const pageNumberParam = searchParams.get('pageNumber') ?? "1";
		const pageSizeParam = searchParams.get('pageSize') ?? "18";

        await Promise.all([
            fetchCurrentUserAsync(),
			fetchUsersDataAsync(pageNumberParam, pageSizeParam)
        ]);
    }

	const onBackClick = () => {
		return navigate(-1);
	}

	const onPageNumberChange = (_, {activePage}) => {
		setPageNumber(activePage);
	};

	const openTecfinityAccountLinkModal = () => {
		setOpenModal(ModalName.TecfinityLink)
	}

	const openTecfinityAccountDeLinkModal = () => {
		setOpenModal(ModalName.TecfinityDeLink)
	}

	const openAccountActivationModal = () => {
		setOpenModal(ModalName.AccountActivation)
	}

	const openAccountDeactivationModal = () => {
		setOpenModal(ModalName.AccountDeactivation)
	}

	const closeModals = () => {
		setOpenModal(null);
		navigate(0);
	}

	const handleTecfinityAccountLinkModalClose = () => {

	}

	useEffect(() => {
		performLoggedInCheck();
	}, []);

	useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes) {
            authorizedPageLoad();
        } else if (loggedIn === LoggedInStatus.Refreshed) {
            reloadPage();
        } else if (loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
			gotoHomePage();
        }
    }, [loggedIn]);

	const allLoadingStates = [
        currentUserCallStatus,
		fetchUsersDataCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

	return (
		<Fragment>
			<SiteHeader currentUser={currentUser}/>
			<Users users={users}
				   tecfinityAccount={tecfinityAccount}
				   tradeAccount={tradeAccount}
				   userToEdit={userToEdit}
				   showTecfinityAccountLinkModal={openModal === ModalName.TecfinityLink}
				   showTecfinityAccountDeLinkModal={openModal === ModalName.TecfinityDeLink}
				   showAccountActivationModal={openModal === ModalName.AccountActivation}
				   showAccountDeactivationModal={openModal === ModalName.AccountDeactivation}
				   openTecfinityAccountLinkModal={openTecfinityAccountLinkModal}
				   openTecfinityAccountDeLinkModal={openTecfinityAccountDeLinkModal}
				   openAccountActivationModal={openAccountActivationModal}
				   openAccountDectivationModal={openAccountDeactivationModal}
				   handleTecfinityAccountLinkModalClose={handleTecfinityAccountLinkModalClose}
				   closeModals={closeModals}
				   setUserToEdit={setUserToEdit}
				   setTradeAccount={setTradeAccount}
				   totalResults={totalResults}
				   pageNumber={pageNumber}
				   pageSize={pageSize}
				   onPageNumberChange={onPageNumberChange}
				   onBackClick={gotoHomePage} />
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={isLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
}

export default UsersPage;