import { ApiCallStatus } from "../../utils/constants";
import { getDetailedOrder, getOrdersByUserId, getOrdersOverview } from "../../api/orderController";
import { sortBy } from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { getUserAddresses } from "../../api/addressController";
import { getDetailedUser } from "../../api/userController";

export const fetchOrdersData = async ({
	pageSize,
	pageNumber,
	setFetchOrdersDataCallStatus,
	setOrders,
	setPageNumber,
	setTotalResults
}) => {
	setFetchOrdersDataCallStatus(ApiCallStatus.InProgress);
	try {
		const response = await getOrdersOverview({
			pageSize,
			pageNumber
		});

		if (response?.success) {
			const orders = response.data;
			const total = response.data.total;
			const skip = response.data.skip;
			const limit = response.data.limit;
			const ordersSorted = sortBy(orders, x => moment(x.dateCreated).format("X"));

			setOrders(ordersSorted);
			setFetchOrdersDataCallStatus(ApiCallStatus.Succeeded);
			setPageNumber(skip + 1);
			setTotalResults(response.total);
		}
		else {
			setOrders([]);
			setFetchOrdersDataCallStatus(ApiCallStatus.Failed);
			setTotalResults(0);
		}
	}
	catch (error) {
		console.error(error)
		setFetchOrdersDataCallStatus(ApiCallStatus.Error);
	}
};

export const fetchUserAddressesData = async ({
	userId,
	setAddresses,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getUserAddresses(userId);

		if (!!response?.success) {
			setAddresses(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
			toast("Could not get user addresses.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		console.error(error)
		setStatusError();
		toast("Could not get user addresses.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const fetchOrdersDataByUserId = async ({
	userId,
	setOrders,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getOrdersByUserId(userId);

		if (!!response?.success) {
			setOrders(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
			toast("Could not get user orders.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		console.error(error)
		setStatusError();
		toast("Could not get user orders.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
}

export const fetchDetailedOrderData = async ({
	orderId,
	setOrder,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getDetailedOrder(orderId);

		if (!!response?.success) {
			setOrder(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
			toast("Could not get order.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		console.error(error)
		setStatusError();
		toast("Could not get order.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};