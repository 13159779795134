import React from 'react';
import { OrderNumberPrefix } from "../../utils/constants";
import moment from "moment";
import {
	Form,
	Grid,
	Button,
	Icon, Segment, Header, Table
} from "semantic-ui-react";
import "./style.css";
import { customDateFormat, SouthAfricanRand } from "../../utils/helpers";
import { round } from "lodash";

const OrderCreateEditForm = ({
	order,
	provinces,
	countries,
	goBack
}) => {
	return (
		<>
			<Form size="small">
				<Grid container>
					<Grid.Row>
						<Grid.Column width={16} verticalAlign="middle">
							<Button floated="left"
									size="tiny"
									type="button"
									className="blue-basic-button borderless"
									onClick={goBack}>
								<Icon name="arrow left"/>
								Back to orders
							</Button>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Segment padded>
							<Grid container>
								<Grid.Row>
									<Grid.Column width={16}>
										<Header as="h6">
											<Icon name="info circle" />
											Information
										</Header>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row centered>
									<Table basic='very' collapsing className="borderless-table">
										<Table.Body>
											<Table.Row>
												<Table.Cell className="borderless" textAlign="right">
													<strong>Order number</strong>
												</Table.Cell>
												<Table.Cell className="borderless">{order.orderNumber}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell className="borderless" textAlign="right">
													<strong>Created on</strong>
												</Table.Cell>
												<Table.Cell className="borderless">{customDateFormat(moment(order.dateCreated))}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell className="borderless" textAlign="right">
													<strong>Customer name</strong>
												</Table.Cell>
												<Table.Cell className="borderless">{order.customerName}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell className="borderless" textAlign="right">
													<strong>Customer email</strong>
												</Table.Cell>
												<Table.Cell className="borderless">{order.customerEmail}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell className="borderless" textAlign="right">
													<strong>Order status</strong>
												</Table.Cell>
												<Table.Cell className="borderless">{order.orderStatus}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell className="borderless" textAlign="right">
													<strong>Sales person</strong>
												</Table.Cell>
												<Table.Cell className="borderless">{order.salesPersonName}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell className="borderless" textAlign="right">
													<strong>Ship via</strong>
												</Table.Cell>
												<Table.Cell className="borderless">{order.shipVia}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell className="borderless" textAlign="right">
													<strong>Payment status</strong>
												</Table.Cell>
												<Table.Cell className="borderless">{order.paymentStatus}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell className="borderless" textAlign="right">
													<strong>Fulfillment status</strong>
												</Table.Cell>
												<Table.Cell className="borderless">{order.fulfillmentStatus}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell className="borderless" textAlign="right">
													<strong>Order total</strong>
												</Table.Cell>
												<Table.Cell className="borderless">{SouthAfricanRand.format(round(order.total, 2))}</Table.Cell>
											</Table.Row>
										</Table.Body>
									</Table>
								</Grid.Row>
							</Grid>
						</Segment>
					</Grid.Row>
					{!!order?.orderLogistics &&
						<Grid.Row>
							<Segment padded>
								<Grid container>
									<Grid.Row>
										<Grid.Column width={16}>
											<Header as="h6">
												<Icon name="truck" />
												Logistics
											</Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row centered>
										<Table basic='very' collapsing className="borderless-table">
											<Table.Body>
												<Table.Row>
													<Table.Cell className="borderless" textAlign="right">
														<strong>Method</strong>
													</Table.Cell>
													<Table.Cell className="borderless">{order.orderLogistics.logisticsMethodName}</Table.Cell>
												</Table.Row>
												<Table.Row>
													<Table.Cell className="borderless" textAlign="right">
														<strong>Fee</strong>
													</Table.Cell>
													<Table.Cell className="borderless">
														{SouthAfricanRand.format(round(order.orderLogistics.fee, 2))}
													</Table.Cell>
												</Table.Row>
												<Table.Row>
													<Table.Cell className="borderless" textAlign="right">
														<strong>Status</strong>
													</Table.Cell>
													<Table.Cell className="borderless">
														{order.orderLogistics.orderLogisticsStatus?.name}
													</Table.Cell>
												</Table.Row>

											</Table.Body>
										</Table>
									</Grid.Row>
									<Grid.Row>
										<Segment padded>
											<Grid container>
												<Grid.Row>
													<Grid.Column width={8}>
														<Table>
															<Table.Header>
																<Table.HeaderCell>Billing address</Table.HeaderCell>
																<Table.HeaderCell />
															</Table.Header>
															<Table.Body>
																{!!order.orderLogistics.billingAddressId &&
																	<>
																		<Table.Row>
																			<Table.Cell>
																				<strong>First Name</strong>
																			</Table.Cell>
																			<Table.Cell>{order.orderLogistics.billingAddress?.firstName}</Table.Cell>
																		</Table.Row>
																		<Table.Row>
																			<Table.Cell>
																				<strong>Last Name</strong>
																			</Table.Cell>
																			<Table.Cell>{order.orderLogistics.billingAddress?.lastName}</Table.Cell>
																		</Table.Row>
																		<Table.Row>
																			<Table.Cell>
																				<strong>Street Address</strong>
																			</Table.Cell>
																			<Table.Cell>{order.orderLogistics.billingAddress?.streetAddress}</Table.Cell>
																		</Table.Row>
																		<Table.Row>
																			<Table.Cell>
																				<strong>City</strong>
																			</Table.Cell>
																			<Table.Cell>{order.orderLogistics.billingAddress?.city}</Table.Cell>
																		</Table.Row>
																		<Table.Row>
																			<Table.Cell>
																				<strong>Suburb</strong>
																			</Table.Cell>
																			<Table.Cell>{order.orderLogistics.billingAddress?.suburb}</Table.Cell>
																		</Table.Row>
																		<Table.Row>
																			<Table.Cell>
																				<strong>Postal Code</strong>
																			</Table.Cell>
																			<Table.Cell>{order.orderLogistics.billingAddress?.postalCode}</Table.Cell>
																		</Table.Row>
																		<Table.Row>
																			<Table.Cell>
																				<strong>Province</strong>
																			</Table.Cell>
																			<Table.Cell>{order.orderLogistics.billingAddress?.province?.name}</Table.Cell>
																		</Table.Row>
																		<Table.Row>
																			<Table.Cell>
																				<strong>Country</strong>
																			</Table.Cell>
																			<Table.Cell>{order.orderLogistics.billingAddress?.country?.name}</Table.Cell>
																		</Table.Row>
																	</>
																}
															</Table.Body>
														</Table>
													</Grid.Column>
													{!!order.orderLogistics.shippingAddressId &&
														<Grid.Column width={8}>
															<Table>
																<Table.Header>
																	<Table.HeaderCell>Shipping address</Table.HeaderCell>
																	<Table.HeaderCell />
																</Table.Header>
																<Table.Body>
																	<Table.Row>
																		<Table.Cell>
																			<strong>First Name</strong>
																		</Table.Cell>
																		<Table.Cell>{order.orderLogistics.shippingAddress?.firstName}</Table.Cell>
																	</Table.Row>
																	<Table.Row>
																		<Table.Cell>
																			<strong>Last Name</strong>
																		</Table.Cell>
																		<Table.Cell>{order.orderLogistics.shippingAddress?.lastName}</Table.Cell>
																	</Table.Row>
																	<Table.Row>
																		<Table.Cell>
																			<strong>Street Address</strong>
																		</Table.Cell>
																		<Table.Cell>{order.orderLogistics.shippingAddress?.streetAddress}</Table.Cell>
																	</Table.Row>
																	<Table.Row>
																		<Table.Cell>
																			<strong>City</strong>
																		</Table.Cell>
																		<Table.Cell>{order.orderLogistics.shippingAddress?.city}</Table.Cell>
																	</Table.Row>
																	<Table.Row>
																		<Table.Cell>
																			<strong>Suburb</strong>
																		</Table.Cell>
																		<Table.Cell>{order.orderLogistics.shippingAddress?.suburb}</Table.Cell>
																	</Table.Row>
																	<Table.Row>
																		<Table.Cell>
																			<strong>Postal Code</strong>
																		</Table.Cell>
																		<Table.Cell>{order.orderLogistics.shippingAddress?.postalCode}</Table.Cell>
																	</Table.Row>
																	<Table.Row>
																		<Table.Cell>
																			<strong>Province</strong>
																		</Table.Cell>
																		<Table.Cell>{order.orderLogistics.shippingAddress?.province?.name}</Table.Cell>
																	</Table.Row>
																	<Table.Row>
																		<Table.Cell>
																			<strong>Country</strong>
																		</Table.Cell>
																		<Table.Cell>{order.orderLogistics.shippingAddress?.country?.name}</Table.Cell>
																	</Table.Row>
																</Table.Body>
															</Table>
														</Grid.Column>
													}
												</Grid.Row>
											</Grid>
										</Segment>
									</Grid.Row>
								</Grid>
							</Segment>
						</Grid.Row>
					}
					{!!order?.orderItems && order.orderItems.length > 0 &&
						<Grid.Row>
							<Segment padded>
								<Grid container>
									<Grid.Row>
										<Grid.Column width={16}>
											<Header as="h6">
												<Icon name="box" />
												Items
											</Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Table sortable selectable textAlign="center">
											<Table.Header>
												<Table.Row>
													<Table.HeaderCell rowSpan="1">
														Sku
													</Table.HeaderCell>
													<Table.HeaderCell rowSpan="1">
														Quantity
													</Table.HeaderCell>
													<Table.HeaderCell rowSpan="1">
														Price
													</Table.HeaderCell>
												</Table.Row>
											</Table.Header>
											<Table.Body>
												{order.orderItems.map((c, index) => {
													return (
														<Table.Row key={index}>
															<Table.Cell>{c.sku}</Table.Cell>
															<Table.Cell>{c.quantity}</Table.Cell>
															<Table.Cell>{SouthAfricanRand.format(round(c.priceSnapshot, 2))}</Table.Cell>
														</Table.Row>
													)
												})}
											</Table.Body>
										</Table>
									</Grid.Row>
								</Grid>
							</Segment>
						</Grid.Row>
					}
					{!!order?.orderPayments && order.orderPayments.length > 0 &&
						<Grid.Row>
							<Segment padded>
								<Grid container>
									<Grid.Row>
										<Grid.Column width={16}>
											<Header as="h6">
												<Icon name="credit card" />
												Payments
											</Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Table sortable selectable compact textAlign="center">
											<Table.Header>
												<Table.Row>
													<Table.HeaderCell rowSpan="1">
														Date
													</Table.HeaderCell>
													<Table.HeaderCell rowSpan="1">
														Amount
													</Table.HeaderCell>
													<Table.HeaderCell rowSpan="1">
														Type
													</Table.HeaderCell>
													<Table.HeaderCell rowSpan="1">
														Status
													</Table.HeaderCell>
												</Table.Row>
											</Table.Header>
											<Table.Body>
												{order.orderPayments.map((c, index) => {
													return (
														<Table.Row key={index}>
															<Table.Cell>{customDateFormat(moment(c.dateCreated))}</Table.Cell>
															<Table.Cell>{SouthAfricanRand.format(round(c.amount, 2))}</Table.Cell>
															<Table.Cell>{c.paymentType?.name}</Table.Cell>
															<Table.Cell>{c.paymentStatus?.name}</Table.Cell>
														</Table.Row>
													)
												})}
											</Table.Body>
										</Table>
									</Grid.Row>
								</Grid>
							</Segment>
						</Grid.Row>
					}
				</Grid>
			</Form>
		</>
	)

}

export default OrderCreateEditForm;