import React, { Fragment, useEffect, useState } from "react";
import SiteHeader from "../../components/SiteHeader";
import { removeAuthCookies } from "../../api/authentication";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { getCurrentUser } from "../../api/authenticationController";
import { ApiCallStatus, LoggedInStatus } from "../../utils/constants";
import Orders from "../../components/Orders";
import { putOrderPaymentToPaid } from "../../api/orderController";
import { cloneDeep } from "lodash";
import { checkIsLoggedIn } from "../PageFunctions/commonFunctions";
import { fetchOrdersData } from "../PageFunctions/orderFunctions";

const fetchCurrentUserData = async ({
    setCurrentUser,
    setCurrentUserCallStatus
}) => {
    setCurrentUserCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await getCurrentUser();
        if (response.success) {
            setCurrentUser(response.data);
            setCurrentUserCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setCurrentUserCallStatus(ApiCallStatus.Failed);
        }
    } catch (e) {
        console.error(e);
        setCurrentUserCallStatus(ApiCallStatus.Error)
    }
};

const updateOrderPaymentToPaid = async ({
	orderId,
    setUpdateOrderPaymentToPaidCallStatus
}) => {
    setUpdateOrderPaymentToPaidCallStatus(ApiCallStatus.InProgress);
    try {
        const response = await putOrderPaymentToPaid(orderId);
        if (response.success) {
            setUpdateOrderPaymentToPaidCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setUpdateOrderPaymentToPaidCallStatus(ApiCallStatus.Failed);
        }
    } catch (e) {
        console.error(e);
        setUpdateOrderPaymentToPaidCallStatus(ApiCallStatus.Error)
    }
};

const clearCookies = async () => {
    await removeAuthCookies();
};

const OrdersPage = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);
	const [orders, setOrders] = useState([]);

	const [pageNumber, setPageNumber] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const [pageSize, setPageSize] = useState(18);

	const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchOrdersDataCallStatus, setFetchOrdersDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [updateOrderPaymentToPaidCallStatus, setUpdateOrderPaymentToPaidCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const reloadPage = () => navigate(0);

	const performLoggedInCheck = async () => {
		await checkIsLoggedIn({
			setLoggedIn
		});
	}

	const clearCookiesAsync = async () => {
		await clearCookies();
	}

	const fetchCurrentUserAsync = async () => {
		await fetchCurrentUserData({
			setCurrentUser,
			setCurrentUserCallStatus
		});
	}

	const fetchOrdersDataAsync = async (pageNumberParam, pageSizeParam) => {
		await fetchOrdersData({
			pageNumber: pageNumberParam ?? "1",
			pageSize: pageSizeParam ?? "18",
			setFetchOrdersDataCallStatus,
			setOrders,
			setPageNumber,
			setTotalResults
		});
	}

	const updateOrderPaymentToPaidAsync = async (orderId) => {
		await updateOrderPaymentToPaid({
			orderId,
			setUpdateOrderPaymentToPaidCallStatus
		});
	}

	const authorizedPageLoad = async () => {
		const pageNumberParam = searchParams.get('pageNumber') ?? "1";
		const pageSizeParam = "18";

        await Promise.all([
            fetchCurrentUserAsync(),
			fetchOrdersDataAsync(pageNumberParam, pageSizeParam)
        ]);
    }

	useEffect(() => {
		performLoggedInCheck();
	}, []);

	useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes) {
            authorizedPageLoad();
        } else if (loggedIn === LoggedInStatus.Refreshed) {
            reloadPage();
        } else if (loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
			gotoHomePage();
        }
    }, [loggedIn]);

	useEffect(() => {
		fetchOrdersDataAsync(pageNumber)
	}, [pageNumber]);

	useEffect(() => {
		if (updateOrderPaymentToPaidCallStatus === ApiCallStatus.Succeeded) {
			reloadPage();
		}
	}, [updateOrderPaymentToPaidCallStatus]);

	const onBackClick = () => {
		return navigate(-1);
	}

	const onPageNumberChange = async (_, {activePage}) => {
		await updateUrlQueryValue("pageNumber", activePage);
	};

	const updateUrlQueryValue = async (key, value) => {
        setFetchOrdersDataCallStatus(ApiCallStatus.NotStarted);
        const currentParams = Object.fromEntries([...searchParams]);
        const paramsClone = cloneDeep(currentParams);

        paramsClone[key] = value;
        await navigate({
            pathname: '/orders',
            search: `?${createSearchParams(paramsClone)}`
        });
    }

	const allLoadingStates = [
        currentUserCallStatus,
		fetchOrdersDataCallStatus,
		updateOrderPaymentToPaidCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

	return (
		<Fragment>
			<SiteHeader currentUser={currentUser}/>
			<Orders orders={orders}
					totalResults={totalResults}
					pageNumber={pageNumber}
					pageSize={pageSize}
					onPageNumberChange={onPageNumberChange}
					markAsPaid={updateOrderPaymentToPaidAsync}
					onBackClick={onBackClick} />
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={isLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default OrdersPage;
