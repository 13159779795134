import axios from "axios";
import Cookies from "js-cookie";
import {storageKeys} from "./storageKeys";
import * as jose from "jose";
import { removeAuthCookies } from "./authentication";
import {includes} from "lodash";
import { LoggedInStatus } from "../utils/constants";

const authenticationUrl = process.env.REACT_APP_IDP_URL;

export const authenticate = async (loginData) => {
    try {
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${authenticationUrl}/api/authentication/`,
        });

        const bodyData = {
            email: loginData.email,
            password: loginData.password,
        };
        const response = await instance.post(`authenticate`, bodyData);
        if (!!response && response.status === 200 && response.data.success) {
            const secret = new TextEncoder().encode(process.env.REACT_APP_JWT_KEY);
            const token = response.data.data.token;
            const { payload } = await jose.jwtVerify(token, secret)

            if (payload) {
                Cookies.set(storageKeys.ACCESS_TOKEN, token, { secure: true, sameSite: 'strict' });
                Cookies.set(storageKeys.REFRESH_TOKEN, response.data.data.refreshToken, { secure: true, sameSite: 'strict' });
            }

            return {
                success: true,
                error: false
            }
        } else {
            return {
                error: true,
                success: false,
                message: "Credentials incorrect. Please try again."
            };
        }
    } catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const refresh = async () => {
    try {
        const accessToken = Cookies.get(storageKeys.ACCESS_TOKEN);
        const refreshToken = Cookies.get(storageKeys.REFRESH_TOKEN);
        const userId = Cookies.get(storageKeys.USER_ID);

        if (!accessToken || !refreshToken || !userId) return LoggedInStatus.No;

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${authenticationUrl}/api/authentication/`,
        });

        const bodyData = {
            userId,
            token: accessToken,
            refreshToken
        };
        const response = await instance.post(`refresh-token`, bodyData);
        if (response && response.status === 200 && response.data.success) {
            const secret = new TextEncoder().encode(process.env.REACT_APP_JWT_KEY);
            const { payload } = await jose.jwtVerify(response.data.token, secret)

            if (payload) {
                Cookies.set(storageKeys.ACCESS_TOKEN, response.data.token, { secure: true, sameSite: 'strict' });
                Cookies.set(storageKeys.REFRESH_TOKEN, response.data.refreshToken, { secure: true, sameSite: 'strict' });
                const expiresAt = payload["exp"];
                const roles = payload["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
                const lowercaseRoles = roles.map(x => x.toLowerCase());
                if (includes(lowercaseRoles, process.env.REACT_APP_ROLE_ARGUS_ADMIN.toLowerCase()) ||
                    includes(lowercaseRoles, process.env.REACT_APP_ROLE_SERVER_ADMIN.toLowerCase())) {
                    if ((Date.now() / 1000) < expiresAt) {
                        const userId = payload["user_id"];
                        Cookies.set(storageKeys.USER_ID, userId, { secure: true, sameSite: 'strict' });
                    }
                    return LoggedInStatus.Refreshed;
                }
            }
            return LoggedInStatus.No;
        } else {
            return LoggedInStatus.No;
        }
    } catch (e) {
        return LoggedInStatus.No;
    }
};

export const getCurrentUser = async () => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${authenticationUrl}/api/authentication/`,
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const requestUrl = `current`;
        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getFullCurrentUser = async () => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${authenticationUrl}/api/authentication/`,
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const requestUrl = `current`;

        return await instance.get(requestUrl)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const logout = async () => {
    try {
        await removeAuthCookies();
    } catch (error) {
        console.error(error);
    }
};

export const forgotPassword = async ({ email }) => {
    try {
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${authenticationUrl}/api/authentication/`,
        });

        const bodyData = {
            email
        };

        const requestUrl = `forgot-password`;
        return await instance.post(requestUrl, bodyData)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const resetPassword = async ({ email, password, token}) => {
    try {
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${authenticationUrl}/api/authentication/`,
        });

        const bodyData = {
            email,
            password,
            token
        };

        const requestUrl = `reset-password`;
        return await instance.post(requestUrl, bodyData)
                             .then((response) => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};
