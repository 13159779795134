import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./main-component/App/App";
import "./index.css";

const loadingMarkup = <div className="py-4 text-center"></div>;

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
        <App/>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
