import axios from "axios";
import {storageKeys} from "./storageKeys";
import Cookies from "js-cookie";

const url = process.env.REACT_APP_IDP_URL;

export const getTecfinityAccount = async (accountNumber) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/tecfinity/`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const requestUrl = `${accountNumber}`;

        return await instance.get(requestUrl)
                             .then(response => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};