import axios from "axios";
import Cookies from "js-cookie";
import { storageKeys } from "./storageKeys";

const url = process.env.REACT_APP_ORDER_API_URL;

export const getProvinces = async () => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/staticdata/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const requestUrl = "provinces";

        return await instance.get(requestUrl)
            .then((response) => {
                if (response.status === 200) return {
                    error: false,
                    success: true,
                    message: response.data.message,
                    data: response.data.data
                };
                else return {
                    error: true,
                    success: false,
                    message: response.data.message
                };
            })
            .catch((error) => {
                if (error.response) {
                    console.error('Server Error:', error.response.status);
                }
                else if (error.request) {
                    console.error('Network Error:', error.request);
                }
                else {
                    console.error('Error:', error.message);
                }
            });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getCountries = async () => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/staticdata/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const requestUrl = "countries";

        return await instance.get(requestUrl)
            .then((response) => {
                if (response.status === 200) return {
                    error: false,
                    success: true,
                    message: response.data.message,
                    data: response.data.data
                };
                else return {
                    error: true,
                    success: false,
                    message: response.data.message
                };
            })
            .catch((error) => {
                if (error.response) {
                    console.error('Server Error:', error.response.status);
                }
                else if (error.request) {
                    console.error('Network Error:', error.request);
                }
                else {
                    console.error('Error:', error.message);
                }
            });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getSalesPeople = async () => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/staticdata/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const requestUrl = "salespeople";

        return await instance.get(requestUrl)
            .then((response) => {
                if (response.status === 200) return {
                    error: false,
                    success: true,
                    message: response.data.message,
                    data: response.data.data
                };
                else return {
                    error: true,
                    success: false,
                    message: response.data.message
                };
            })
            .catch((error) => {
                if (error.response) {
                    console.error('Server Error:', error.response.status);
                }
                else if (error.request) {
                    console.error('Network Error:', error.request);
                }
                else {
                    console.error('Error:', error.message);
                }
            });
    } catch (e) {
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getOrders = async ({pageSize, pageNumber}) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/order`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const params = {
            limit: pageSize,
            skip: pageNumber - 1
        }

        return await instance.get("", { params: params })
                             .then(response => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data,
                                     total: response.data.total
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch(error => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getOrdersOverview = async ({pageSize, pageNumber}) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/order/`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const skip = !!pageNumber ? Number(pageNumber) - 1 : 0;
        const params = {
            limit: pageSize,
            skip
        }

        const requestUrl = "overviews";

        return await instance.get(requestUrl, { params: params })
                             .then(response => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data,
                                     total: response.data.total
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch(error => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getDetailedOrder = async (orderId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/order/`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const requestUrl = `detailed/${orderId}`;

        return await instance.get(requestUrl)
            .then(response => {
                if (response.status === 200) return {
                    error: false,
                    success: true,
                    message: response.data.message,
                    data: response.data.data,
                    total: response.data.total
                };
                else return {
                    error: true,
                    success: false,
                    message: response.data.message
                };
            })
            .catch(error => {
                if (error.response) {
                    console.error('Server Error:', error.response.status);
                }
                else if (error.request) {
                    console.error('Network Error:', error.request);
                }
                else {
                    console.error('Error:', error.message);
                }
            });
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getOrderById = async (orderId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/order/`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const response = await instance.get(`${orderId}`);

        return response?.data ?? null
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
}

export const getOrdersByUserId = async (orderId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/order/`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const response = await instance.get(`user/${orderId}`);

        return response?.data ?? null
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
}

export const getDetailedOrderById = async (orderId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/order/`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const response = await instance.get(`detailed/${orderId}`);

        return response?.data ?? null
    } catch (error) {
        console.error(error)
    }
}

export const markOrderItemAsFulfilled = async ({orderId, orderItemId, statusId}) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/order/`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response = await instance.put(`item-fulfillment-status/${orderId}/${orderItemId}/${statusId}`);

        return response?.data ?? null
    } catch (e) {
        console.error(e)
        
    }
};

export const putOrderItemFulfillmentStatus = async ({orderId, body}) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/order/`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response = await instance.put(`fulfill-items/${orderId}`, body);

        return response?.data ?? null
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const updateOrderPaymentStatus = async ({orderId}) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/order/`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response = await instance.put(`payment-status/${orderId}`);

        return response?.data ?? null
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const putOrderPaymentToPaid = async (orderId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/order/`
        });

        if (!!token) {
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        const requestUrl = `trigger-mark-as-paid/${orderId}`;

        return await instance.put(requestUrl)
                                .then((response) => {
                                    if (response.status === 204) return {
                                     error: false,
                                     success: true,
                                 };
                                    else return {
                                        error: true,
                                        success: false,
                                        message: response.data.message
                                    };
                                })
                                .catch((error) => {
                                    if (error.response) {
                                        console.error('Server Error:', error.response.status);
                                    } else if (error.request) {
                                        console.error('Network Error:', error.request);
                                    } else {
                                        console.error('Error:', error.message);
                                    }
                                });
    } catch (e) {
        console.error(e)

    }
};