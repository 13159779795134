import Cookies from "js-cookie";
import { storageKeys } from "./storageKeys";
import axios from "axios";

const url = process.env.REACT_APP_ORDER_API_URL;

export const getUserAddresses = async (userId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/address/`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const requestUrl = `user/${userId}`;

        return await instance.get(requestUrl)
            .then(response => {
                if (response.status === 200) return {
                    error: false,
                    success: true,
                    message: response.data.message,
                    data: response.data.data,
                    total: response.data.total
                };
                else return {
                    error: true,
                    success: false,
                    message: response.data.message
                };
            })
            .catch(error => {
                if (error.response) {
                    console.error('Server Error:', error.response.status);
                }
                else if (error.request) {
                    console.error('Network Error:', error.request);
                }
                else {
                    console.error('Error:', error.message);
                }
            });
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};