import React, { Fragment, useEffect, useState } from "react";
import { FormType } from "../../utils/constants";
import { Grid } from "semantic-ui-react";
import OrderCreateEditForm from "../../forms/OrderCreateEditForm";

const OrderCreateEdit = ({
    order,
    provinces,
    countries,
    onBackClick
}) => {
    console.log("order", order)
    return (
        <div>
            <div className="argus-title-background mb-5">
                <div className="argus-title">
                    Order
                </div>
            </div>
            <Grid container>
                <Grid.Row verticalAlign="middle" textAlign="center">
                    {!!order &&
                        <OrderCreateEditForm
                            order={order}
                            provinces={provinces}
                            countries={countries}
                            goBack={onBackClick}  />
                    }
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default OrderCreateEdit;