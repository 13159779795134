import { Button, Grid, Icon, Menu, Pagination, Popup, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import React from "react";
import { AnnouncementType } from "../../utils/constants";
import AnnouncementActivationModal from "../AnnouncementActivationModal";
import AnnouncementDeactivationModal from "../AnnouncementDeactivationModal";


const Announcements = ({
	onBackClick,
	gotoCreatePage,
	onPageNumberChange,
	pageNumber,
	pageSize,
	totalResults,
	announcements,
	announcementToEdit,
	setAnnouncementToEdit,
	showAnnouncementActivationModal,
	showAnnouncementDeactivationModal,
	openAnnouncementActivationModal,
	openAnnouncementDeactivationModal,
	closeModals
}) => {

	const handleDeactivateAnnouncementClick = announcement => {
		if (!!announcement) {
			setAnnouncementToEdit(announcement);
			openAnnouncementDeactivationModal();
		}
	}

	const handleActivateAnnouncementClick = announcement => {
		if (!!announcement) {
			setAnnouncementToEdit(announcement);
			openAnnouncementActivationModal();
		}
	}

	return (
		<div className="argus-min-height">
			<div className="argus-title-background mb-5">
				<div className="argus-title">Announcements</div>
			</div>
			<Grid container>
				<Grid.Row>
					<Grid.Column width={16} verticalAlign="middle">
						<Button floated="left"
						        size="tiny"
						        type="button"
						        className="blue-basic-button borderless"
						        onClick={onBackClick}>
							<Icon name="arrow left"/>
							Back
						</Button>
						<Button floated="right"
						        type="button"
						        className="blue-button"
						        onClick={gotoCreatePage}>
							<Icon name="plus circle"/>
							Create
						</Button>
					</Grid.Column>
				</Grid.Row>
				{!!announcements &&
					<>
						<Grid.Row>
							<Grid.Column width={16}>
								<Table sortable selectable compact textAlign="center">
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell className="sticky-top" rowSpan='1'>
												Title
											</Table.HeaderCell>
											<Table.HeaderCell className="sticky-top" rowSpan='1'>
												Type
											</Table.HeaderCell>
											<Table.HeaderCell className="sticky-top" rowSpan='1'>
												Active
											</Table.HeaderCell>
											<Table.HeaderCell className="sticky-top" rowSpan="1">
												Start date
											</Table.HeaderCell>
											<Table.HeaderCell className="sticky-top" rowSpan="1">
												End date
											</Table.HeaderCell>
											<Table.HeaderCell className="sticky-top"
											                  rowSpan="1">
												Actions
											</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{!!announcements && announcements.map((c, index) => {
											let actionRequired = false;
											const canActivate = moment(c.endDate).isAfter(moment()) && !c.isActive;
											const canDeactivate = !!c.isActive;


											const announcementType = Object.keys(AnnouncementType)
											                               .find(key => AnnouncementType[key] === c.type)
											return (
												<Table.Row key={index}>
													<Table.Cell>
														<Link to={`/announcement/${c.announcementId}`}>
															{c.title}
														</Link>
													</Table.Cell>
													<Table.Cell>{announcementType}</Table.Cell>
													<Table.Cell>
														<Icon name={`${c.isActive ? "check" : "times"}`}
														      color={`${c.isActive ? "green" : "red"}`}/>
													</Table.Cell>
													<Table.Cell>{moment(c.startDate).format("YYYY-MM-DD")}</Table.Cell>
													<Table.Cell>{moment(c.endDate).format("YYYY-MM-DD")}</Table.Cell>
													<Table.Cell textAlign="center">
														<Popup flowing
														       basic
														       hoverable
														       trigger={<Icon
															       color={actionRequired ? "red" : "black"}
															       name={actionRequired ? "exclamation circle" : "ellipsis vertical"}/>}>
															<Menu vertical className="argus-menu" borderless fluid>
																{!!canDeactivate &&
																	<Menu.Item to="#"
																	           as={Link}
																	           onClick={() => handleDeactivateAnnouncementClick(c)}>
																		Deactivate
																	</Menu.Item>
																}
																{!!canActivate &&
																	<Menu.Item to="#"
																			   as={Link}
																			   onClick={() => handleActivateAnnouncementClick(c)}>
																		Activate
																	</Menu.Item>
																}
															</Menu>
														</Popup>

													</Table.Cell>
												</Table.Row>
											)
										})}
									</Table.Body>
								</Table>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column width={16} textAlign="right">
								{totalResults && totalResults > 0 &&
									<Pagination
										activePage={pageNumber}
										boundaryRange={1}
										size="mini"
										onPageChange={onPageNumberChange}
										siblingRange={1}
										totalPages={Math.ceil(totalResults / pageSize)}
									/>
								}
							</Grid.Column>
						</Grid.Row>
					</>
				}
			</Grid>
			{!!showAnnouncementActivationModal &&
				<AnnouncementActivationModal
					open={showAnnouncementActivationModal}
					setOpen={openAnnouncementActivationModal}
					setClose={closeModals}
                    announcement={announcementToEdit}
				/>
			}
            {!!showAnnouncementDeactivationModal &&
                <AnnouncementDeactivationModal
                    open={showAnnouncementDeactivationModal}
                    setOpen={openAnnouncementDeactivationModal}
                    setClose={closeModals}
                    announcement={announcementToEdit}
                />
            }
		</div>
	)
}

export default Announcements;