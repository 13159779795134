import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiCallStatus, FormType, LoggedInStatus } from "../../utils/constants";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import {
    checkIsLoggedIn,
    clearCookies, fetchCountryData,
    fetchCurrentUserData, fetchProvinceData
} from "../PageFunctions/commonFunctions";
import OrderCreateEdit from "../../components/OrderCreateEdit";
import {
    fetchDetailedOrderData
} from "../PageFunctions/orderFunctions";

const OrderCreateEditPage = () => {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);
    const [orderData, setOrderData] = useState(null);
    const [countryData, setCountryData] = useState(null);
    const [provinceData, setProvinceData] = useState(null);

    const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
    const [fetchOrderDataCallStatus, setFetchOrderDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [fetchProvinceDataCallStatus, setFetchProvinceDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [fetchCountryDataCallStatus, setFetchCountryDataCallStatus] = useState(ApiCallStatus.NotStarted);

    const gotoHomePage = () => navigate("/");
    const gotoOrdersPage = () => navigate("/orders");
    const reloadPage = () => navigate(0);

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setCurrentUserCallStatus
        });
    }

    const fetchOrderDataAsync = async () => {
        if (!!orderId) {
            await fetchDetailedOrderData({
                orderId,
                setOrder: setOrderData,
                setStatusInProgress: () => setFetchOrderDataCallStatus(ApiCallStatus.InProgress),
                setStatusSuccess: () => setFetchOrderDataCallStatus(ApiCallStatus.Succeeded),
                setStatusFailed: () => setFetchOrderDataCallStatus(ApiCallStatus.Failed),
                setStatusError: () => setFetchOrderDataCallStatus(ApiCallStatus.Error),
            });
        }
    }

    const fetchCountryDataAsync = async () => {
        if (!!orderId) {
            await fetchCountryData({
                setCountries: setCountryData,
                setStatusInProgress: () => setFetchCountryDataCallStatus(ApiCallStatus.InProgress),
                setStatusSuccess: () => setFetchCountryDataCallStatus(ApiCallStatus.Succeeded),
                setStatusFailed: () => setFetchCountryDataCallStatus(ApiCallStatus.Failed),
                setStatusError: () => setFetchCountryDataCallStatus(ApiCallStatus.Error),

            });
        }
    }

    const fetchProvinceDataAsync = async () => {
        if (!!orderId) {
            await fetchProvinceData({
                setProvinces: setProvinceData,
                setStatusInProgress: () => setFetchProvinceDataCallStatus(ApiCallStatus.InProgress),
                setStatusSuccess: () => setFetchProvinceDataCallStatus(ApiCallStatus.Succeeded),
                setStatusFailed: () => setFetchProvinceDataCallStatus(ApiCallStatus.Failed),
                setStatusError: () => setFetchProvinceDataCallStatus(ApiCallStatus.Error),

            });
        }
    }

    const authorizedPageLoad = async () => {
        await Promise.all([
            fetchCurrentUserAsync(),
            fetchOrderDataAsync(),
            fetchProvinceDataAsync(),
            fetchCountryDataAsync()
        ]);
    }

    useEffect(() => {
        performLoggedInCheck();
    }, []);

    useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes) {
            authorizedPageLoad();
        } else if (loggedIn === LoggedInStatus.Refreshed) {
            reloadPage();
        } else if (loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
            gotoHomePage();
        }
    }, [loggedIn]);

    const allLoadingStates = [
        currentUserCallStatus,
        fetchOrderDataCallStatus,
        fetchProvinceDataCallStatus,
        fetchCountryDataCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

    return (
        <Fragment>
            <SiteHeader currentUser={currentUser}/>
            <OrderCreateEdit
                onBackClick={gotoOrdersPage}
                order={orderData}
                provinces={provinceData}
                countries={countryData} />
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </Fragment>
    );
};
export default OrderCreateEditPage;