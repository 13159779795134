import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiCallStatus, FormType, LoggedInStatus } from "../../utils/constants";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import AnnouncementCreateEdit from "../../components/AnnouncementCreateEdit";
import {
	checkIsLoggedIn,
	clearCookies,
	fetchCurrentUserData
} from "../PageFunctions/commonFunctions";
import {
	createAnnouncementData
} from "../PageFunctions/announcementFunctions";
import moment from "moment";

const AnnouncementCreatePage = () => {
	const navigate = useNavigate();
	const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
	const [currentUser, setCurrentUser] = useState(null);

	const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
	const [createAnnouncementDataCallStatus, setCreateAnnouncementDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const gotoAnnouncementsPage = () => navigate("/announcements");
	const reloadPage = () => navigate(0);

	const performLoggedInCheck = async () => {
		await checkIsLoggedIn({
			setLoggedIn
		});
	}

	const clearCookiesAsync = async () => {
		await clearCookies();
	}

	const fetchCurrentUserAsync = async () => {
		await fetchCurrentUserData({
			setCurrentUser,
			setCurrentUserCallStatus
		});
	}

	const createAnnouncementDataAsync = async ({
		title,
		html,
		bannerImage,
		startDate,
		endDate,
		type
	}) => {
		await createAnnouncementData({
			title,
			html,
			bannerImage,
			startDate,
			endDate,
			type,
			setCreateAnnouncementDataCallStatus
		});
	}

	const authorizedPageLoad = async () => {
		await Promise.all([
			fetchCurrentUserAsync()
		]);
	}

	useEffect(() => {
		performLoggedInCheck();
	}, []);

	useEffect(() => {
		if (loggedIn === LoggedInStatus.Yes) {
			authorizedPageLoad();
		} else if (loggedIn === LoggedInStatus.Refreshed) {
			reloadPage();
		} else if (loggedIn === LoggedInStatus.No) {
			clearCookiesAsync();
			gotoHomePage();
		}
	}, [loggedIn]);

	useEffect(() => {
		if (createAnnouncementDataCallStatus === ApiCallStatus.Succeeded) {
			gotoAnnouncementsPage();
		}
	}, [createAnnouncementDataCallStatus]);

	const onSubmit = async (values) => {
		const timezoneOffset = new Date().getTimezoneOffset();
		const offsetHours = parseInt(Math.abs(timezoneOffset / 60));
		const offsetMinutes = Math.abs(timezoneOffset % 60);
		const offsetSign = timezoneOffset > 0 ? "-" : "+";
		const formattedOffset = offsetSign + String(offsetHours).padStart(2, '0') + ":" + String(offsetMinutes).padStart(2, '0');

		const startDateInput = moment(`${values.startDate} ${values.startTime}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DDTHH:mm:ss') + formattedOffset;
		const endDateInput = moment(`${values.endDate} ${values.endTime}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DDTHH:mm:ss') + formattedOffset;

		await createAnnouncementDataAsync({
			title: values.title,
			html: "",
			bannerImage: values.bannerImage,
			startDate: startDateInput,
			endDate: endDateInput,
			type: values.type
		});
	}

	const allLoadingStates = [
		currentUserCallStatus,
		createAnnouncementDataCallStatus
	];

	const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

	return (
		<Fragment>
			<SiteHeader currentUser={currentUser}/>
			<AnnouncementCreateEdit
				formType={FormType.Create}
				onBackClick={gotoAnnouncementsPage}
				submit={onSubmit}/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={isLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default AnnouncementCreatePage;