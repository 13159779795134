import React from 'react';
import { Box, Modal, IconButton } from '@mui/material';
import { Button, Image } from 'semantic-ui-react';
import CloseIcon from '@mui/icons-material/Close';
import { AnnouncementType } from '../../utils/constants';
import "./style.css";

const AnnouncementModal = ({
	open,
	setClose,
	type,
	image,
	gotoLink
}) => {

	const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center', // Centers content vertically in the modal
		alignItems: 'center', // Centers content horizontally
		padding: 4,
		boxShadow: 24,
		bgcolor: 'white',
		minWidth: '30vw',
		minHeight: '30vw',
	};
	const closeModal = () => {
		setClose();
	}

	return (
		<>
			<Modal open={open} onClose={closeModal} disableBackdropClick disableEscapeKeyDown>
				<Box sx={modalStyle} className="modalContent">
					<IconButton onClick={closeModal} style={{ position: 'absolute', top: 0, right: 0 }}>
						<CloseIcon />
					</IconButton>
					<Image src={image} className="modalImage" />
					<div className="buttonContainer">
						{type === AnnouncementType.Acknowledgement &&
							<Button className="blue-button" onClick={closeModal}>
								Acknowledge
							</Button>
						}
						{type === AnnouncementType.Promotional &&
							<>
								<Button className="blue-button" href={gotoLink} target="_blank">
									View
								</Button>
							</>
						}
					</div>
				</Box>
			</Modal>
		</>
	);
}

export default AnnouncementModal;
