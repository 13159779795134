import { ApiCallStatus } from "../../utils/constants";
import { getDetailedUser, getUser, getUsers } from "../../api/userController";
import { toast } from "react-toastify";

export const fetchUsersData = async ({
    pageSize,
    pageNumber,
    setUsers,
    setFetchUsersDataCallStatus,
    setTotalResults
}) => {
    setFetchUsersDataCallStatus(ApiCallStatus.InProgress);
    try {
        const getUsersDataOptions = {
            pageSize,
            pageNumber
        };

        const response = await getUsers(getUsersDataOptions);
        if (!!response?.success) {
            const users = response.data.map(user => {
                user.tradeAccountRequested = user.tradeAccounts.filter(x => !x.accountNumber).length > 0;
                user.isTradeAccount = user.tradeAccounts.filter(x => x.accountNumber).length > 0;

                return user;
            });

            setUsers(users);
            setTotalResults(response.total);
            setFetchUsersDataCallStatus(ApiCallStatus.Succeeded);
        }
        else {
            setUsers([]);
            setTotalResults(0);
            setFetchUsersDataCallStatus(ApiCallStatus.Failed);
        }

    }
    catch (error) {
        console.error(error)
        setFetchUsersDataCallStatus(ApiCallStatus.Error);
    }
};

export const fetchDetailedUserData = async ({
    userId,
    setUser,
    setStatusInProgress,
    setStatusSuccess,
    setStatusFailed,
    setStatusError
}) => {
    setStatusInProgress();
    try {
        const response = await getDetailedUser(userId);

        if (!!response?.success) {
            setUser(response.data);
            setStatusSuccess();
        }
        else {
            setStatusFailed();
            toast("Could not get user.", {
                type: "error",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    }
    catch (error) {
        console.error(error)
        setStatusError();
        toast("Could not get user.", {
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }
};