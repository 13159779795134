import React, { useEffect, useRef, useState } from 'react';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Controller, useForm } from "react-hook-form";
import { AnnouncementType, FormType, OrderNumberPrefix, OrderPaymentStatus } from "../../utils/constants";
import moment from "moment";
import {
	Form,
	Grid,
	Button,
	Icon, Segment, Header, Table, Popup, Menu
} from "semantic-ui-react";
import { ErrorMessage } from "@hookform/error-message";
import TextEditor from "../../components/TextEditor";
import AnnouncementModal from "../../components/AnnouncementModal";
import { toast } from "react-toastify";
import "./style.css";
import { Link } from "react-router-dom";
import { customDateFormat, SouthAfricanRand } from "../../utils/helpers";
import { find, round } from "lodash";

const UserCreateEditForm = ({
	user,
	orders,
	addresses,
	countries,
	provinces,
	salesPeople,
	goBack
}) => {
	return (
		<>
			<Form size="small">
				<Grid container>
					<Grid.Row>
						<Grid.Column width={16} verticalAlign="middle">
							<Button floated="left"
									size="tiny"
									type="button"
									className="blue-basic-button borderless"
									onClick={goBack}>
								<Icon name="arrow left"/>
								Back to Users
							</Button>
							{/*<Button*/}
							{/*	className="red-button"*/}
							{/*	type="button"*/}
							{/*	floated="right"*/}
							{/*	onClick={onCancelDeactivateClick}>*/}
							{/*	<Icon name="trash"/>*/}
							{/*	{formType === FormType.Create ? "Cancel" : "Deactivate"}*/}
							{/*</Button>*/}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Segment padded>
							<Grid container>
								<Grid.Row>
									<Grid.Column width={16}>
										<Header as="h6">
											<Icon name="info circle" />
											Information
										</Header>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row centered>
									<Table basic='very' collapsing className="borderless-table">
										<Table.Body>
											<Table.Row>
												<Table.Cell className="borderless" textAlign="right">
													<strong>First name</strong>
												</Table.Cell>
												<Table.Cell className="borderless">{user.firstName}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell className="borderless" textAlign="right">
													<strong>Last name</strong>
												</Table.Cell>
												<Table.Cell className="borderless">{user.lastName}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell className="borderless" textAlign="right">
													<strong>Email</strong>
												</Table.Cell>
												<Table.Cell className="borderless">{user.email}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell className="borderless" textAlign="right">
													<strong>Email confirmed</strong>
												</Table.Cell>
												<Table.Cell className="borderless">{user.emailConfirmed ? "Yes" : "No"}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell className="borderless" textAlign="right">
													<strong>Phone number</strong>
												</Table.Cell>
												<Table.Cell className="borderless">{user.phone}</Table.Cell>
											</Table.Row>
											<Table.Row>
												<Table.Cell className="borderless" textAlign="right">
													<strong>Phone number confirmed</strong>
												</Table.Cell>
												<Table.Cell className="borderless">{user.phoneNumberConfirmed ? "Yes" : "No"}</Table.Cell>
											</Table.Row>
										</Table.Body>
									</Table>
								</Grid.Row>
							</Grid>
						</Segment>
					</Grid.Row>
					{!!addresses && addresses.length > 0 &&
						<Grid.Row>
							<Segment padded>
								<Grid container>
									<Grid.Row>
										<Grid.Column width={16}>
											<Header as="h6">
												<Icon name="home" />
												Addresses
											</Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Table sortable selectable compact textAlign="center">
											<Table.Header>
												<Table.Row>
													<Table.HeaderCell className="sticky-top" rowSpan="1">
														Street Address
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														Suburb
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														City
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														Province
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														Country
													</Table.HeaderCell>
												</Table.Row>
											</Table.Header>
											<Table.Body>
												{addresses.map((c, index) => {
													const country = find(countries, x => x.countryId === c.countryId);
													const province = find(provinces, x => x.provinceId === c.provinceId);

													return (
														<Table.Row key={index}>
															<Table.Cell>
																{c.streetAddress}
															</Table.Cell>
															<Table.Cell>{c.suburb}</Table.Cell>
															<Table.Cell>{c.city}</Table.Cell>
															<Table.Cell>{province?.name}</Table.Cell>
															<Table.Cell>{country?.name}</Table.Cell>
														</Table.Row>
													)
												})}
											</Table.Body>
										</Table>
									</Grid.Row>
								</Grid>
							</Segment>
						</Grid.Row>
					}
					{!!user?.tradeAccounts && user.tradeAccounts.length > 0 &&
						<Grid.Row>
							<Segment padded>
								<Grid container>
									<Grid.Row>
										<Grid.Column width={16}>
											<Header as="h6">
												<Icon name="shop" />
												Trade accounts
											</Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Table sortable selectable compact textAlign="center">
											<Table.Header>
												<Table.Row>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														Name
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top" rowSpan="1">
														Account number
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														Telephone
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														Sales Person
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														Email
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														Category
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														ID Number
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														Company name/Reg No.
													</Table.HeaderCell>
												</Table.Row>
											</Table.Header>
											<Table.Body>
												{user.tradeAccounts.map((c, index) => {
													const salesPerson = find(salesPeople, x => x.code === c.salesman);

													return (
														<Table.Row key={index}>
															<Table.Cell>{c.name}</Table.Cell>
															<Table.Cell>{c.accountNumber}</Table.Cell>
															<Table.Cell>{c.telephone}</Table.Cell>
															<Table.Cell>{salesPerson ?? c.salesman}</Table.Cell>
															<Table.Cell>{c?.email}</Table.Cell>
															<Table.Cell>{c?.category}</Table.Cell>
															<Table.Cell>{c?.personalIdNumber}</Table.Cell>
															<Table.Cell>{c?.companyRegNo}</Table.Cell>
														</Table.Row>
													)
												})}
											</Table.Body>
										</Table>
									</Grid.Row>
								</Grid>
							</Segment>
						</Grid.Row>
					}
					{!!orders && orders.length > 0 &&
						<Grid.Row>
							<Segment padded>
								<Grid container>
									<Grid.Row>
										<Grid.Column width={16}>
											<Header as="h6">
												<Icon name="unordered list" />
												Orders
											</Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Table sortable selectable compact textAlign="center">
											<Table.Header>
												<Table.Row>
													<Table.HeaderCell className="sticky-top" rowSpan="1">
														#
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														Date
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														Subtotal
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														Order status
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														Payment status
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														Fulfillment status
													</Table.HeaderCell>
													<Table.HeaderCell className="sticky-top"
																	  rowSpan="1">
														Delivery status
													</Table.HeaderCell>
												</Table.Row>
											</Table.Header>
											<Table.Body>
												{orders.map((c, index) => {
													return (
														<Table.Row key={index}>
															<Table.Cell>
																<Link to={`/order/${c.orderId}`}>
																	{`${OrderNumberPrefix}${c.orderNumber}`}
																</Link>
															</Table.Cell>
															<Table.Cell>{customDateFormat(moment(c.dateCreated))}</Table.Cell>
															<Table.Cell>{SouthAfricanRand.format(round(c.subtotal, 2))}</Table.Cell>
															<Table.Cell>{c.orderStatus?.name}</Table.Cell>
															<Table.Cell>{c.orderPaymentStatus?.name}</Table.Cell>
															<Table.Cell>{c.orderFulfillmentStatus?.name}</Table.Cell>
															<Table.Cell>{c.orderLogistics?.name}</Table.Cell>
														</Table.Row>
													)
												})}
											</Table.Body>
										</Table>
									</Grid.Row>
								</Grid>
							</Segment>
						</Grid.Row>
					}
				</Grid>
			</Form>
		</>
	)

}

export default UserCreateEditForm;