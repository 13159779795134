import React, { Fragment, useEffect, useState } from "react";
import { isLoggedIn, removeAuthCookies } from "../../api/authentication";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ApiCallResult, PageStatus } from "../../utils/constants";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";

import { resetPassword } from "../../api/authenticationController";
import ResetPassword from "../../components/ResetPassword";

const checkIsLoggedIn = async ({
	setLoggedIn
}) => {
	try {
		const response = await isLoggedIn();
		setLoggedIn(response);
	}
	catch (e) {
		console.error(e);
	}
}

const clearCookies = async () => {
	await removeAuthCookies();
};

const createResetPasswordData = async ({
	email,
	password,
	token,
	setPageStatus,
	setCreateResetPasswordDataResult
}) => {
	setPageStatus(PageStatus.DataFetchStarted)
	try {
		const resetPasswordOptions = {
			email: encodeURI(email),
			password,
			token: encodeURI(token)
		}

		const response = await resetPassword(resetPasswordOptions)

		if (response.success) {
			setCreateResetPasswordDataResult(ApiCallResult.Success);
		}
		else {
			setCreateResetPasswordDataResult(ApiCallResult.Failed);
		}
	}
	catch (error) {
		console.error(error);
		setCreateResetPasswordDataResult(ApiCallResult.Error);
	}
	finally {
		setPageStatus(PageStatus.DataFetchComplete);
	}
}

const ForgotPasswordPage = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [pageStatus, setPageStatus] = useState(PageStatus.NotLoaded);
	const [loggedIn, setLoggedIn] = useState(null);
	const [createResetPasswordDataResult, setCreateResetPasswordDataResult] = useState(ApiCallResult.NoResult);

	useEffect(() => {
		const performLoggedInCheck = async () => {
			const checkIsLoggedInOptions = {
				setLoggedIn,
				setPageStatus
			}
			await checkIsLoggedIn(checkIsLoggedInOptions);
		}

		performLoggedInCheck();
	}, []);

	useEffect(() => {
		const clearCookiesAsync = async () => {
			await clearCookies();
		}

		if (loggedIn === "true") {
			navigate("/");
		}
		else if (loggedIn === "false") {
			clearCookiesAsync();
			setPageStatus(PageStatus.DataFetchComplete);
		}
	}, [loggedIn]);

	useEffect(() => {
		const tokenParam = searchParams.get('token');
		const emailParam = searchParams.get('email');

		if (!tokenParam || !emailParam) {
			navigate("/error")
		}
	}, [searchParams]);

	const onResetClick = () => {
		navigate(0)
	}

	const onSubmit = async ({password}) => {
		const createResetPasswordDataAsync = async (password) => {
			const createResetPasswordDataOptions = {
				email: searchParams.get('email'),
				token: searchParams.get('token'),
				password,
				setPageStatus,
				setCreateResetPasswordDataResult
			}

			await createResetPasswordData(createResetPasswordDataOptions);
		}

		createResetPasswordDataAsync(password);
	}

	const pageStatusLoading =
		pageStatus !== PageStatus.AuthChecksFailed &&
		pageStatus !== PageStatus.DataFetchFailed &&
		pageStatus !== PageStatus.DataFetchComplete

	return (
		<Fragment>
			<SiteHeader/>
			<ResetPassword onSubmit={onSubmit}
						   onResetClick={onResetClick}
						   createResetPasswordDataResult={createResetPasswordDataResult}/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageStatusLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
			
		</Fragment>
	);
}

export default ForgotPasswordPage;