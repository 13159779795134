import axios from "axios";
import {storageKeys} from "./storageKeys";
import Cookies from "js-cookie";

const url = process.env.REACT_APP_IDP_URL;

export const postLinkTecfinityAccount = async (postLinkTecfinityAccountOptions) => {

    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);

        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/user/`
        });

        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const body = {
            userId: postLinkTecfinityAccountOptions.userId,
            tecfinityAccountNumber: postLinkTecfinityAccountOptions.tecfinityAccountNumber
        }

        const requestUrl = `link-tecfinity`;

        return await instance.post(requestUrl, body)
                             .then(response => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const deleteLinkTecfinityAccount = async ({
    userId,
    tecfinityAccountNumber
}) => {

    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/user/`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const body = {
            tecfinityAccountNumber
        }
        const response = await instance.put(`delink-tecfinity/${userId}`, body);

        return response?.data ?? null
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const postActivateUserAccount = async (userId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/user/`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const requestUrl = `activate/${userId}`;

        return await instance.put(requestUrl)
                             .then(response => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
}

export const getUsers = async ({pageSize, pageNumber}) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/user/`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const params = {
            limit: pageSize,
            skip: pageNumber - 1
        }
        const requestUrl = ``;
        const config = {
            params: params
        }
        return await instance.get(requestUrl, config)
                             .then(response => {
                                 if (response.status === 200) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data,
                                     total: response.data.total
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch(error => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getUser = async (userId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/user/`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const requestUrl = `${userId}`;

        return await instance.get(requestUrl)
            .then(response => {
                if (response.status === 200) return {
                    error: false,
                    success: true,
                    message: response.data.message,
                    data: response.data.data,
                    total: response.data.total
                };
                else return {
                    error: true,
                    success: false,
                    message: response.data.message
                };
            })
            .catch(error => {
                if (error.response) {
                    console.error('Server Error:', error.response.status);
                }
                else if (error.request) {
                    console.error('Network Error:', error.request);
                }
                else {
                    console.error('Error:', error.message);
                }
            });
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getDetailedUser = async (userId) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/user/`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const requestUrl = `detailed/${userId}`;

        return await instance.get(requestUrl)
            .then(response => {
                if (response.status === 200) return {
                    error: false,
                    success: true,
                    message: response.data.message,
                    data: response.data.data,
                    total: response.data.total
                };
                else return {
                    error: true,
                    success: false,
                    message: response.data.message
                };
            })
            .catch(error => {
                if (error.response) {
                    console.error('Server Error:', error.response.status);
                }
                else if (error.request) {
                    console.error('Network Error:', error.request);
                }
                else {
                    console.error('Error:', error.message);
                }
            });
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const getUserTecfinityAccounts = async () => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/user/`
        });
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response = await instance.get("tecfinity");

        return response?.data ?? null
    }
    catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};

export const putTradeAccount = async ({
    tradeAccountId,
    accountNumber,
    companyRegNo,
    personalIdNumber
}) => {
    try {
        const token = Cookies.get(storageKeys.ACCESS_TOKEN);
        const instance = axios.create({
            withCredentials: true,
            baseURL: `${url}/api/user/`
        });

        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const body = {
            accountNumber,
            companyRegNo,
            personalIdNumber
        }

        const requestUrl = `tecfinity/${tradeAccountId}`;

        return await instance.put(requestUrl, body)
                             .then(response => {
                                 if (response.status === 204) return {
                                     error: false,
                                     success: true,
                                     message: response.data.message,
                                     data: response.data.data
                                 };
                                 else return {
                                     error: true,
                                     success: false,
                                     message: response.data.message
                                 };
                             })
                             .catch((error) => {
                                 if (error.response) {
                                     console.error('Server Error:', error.response.status);
                                 }
                                 else if (error.request) {
                                     console.error('Network Error:', error.request);
                                 }
                                 else {
                                     console.error('Error:', error.message);
                                 }
                             });
    } catch (e) {
        console.error(e);
        return {
            error: true,
            success: false,
            message: "Something went wrong. Please try again."
        };
    }
};
