import React, { Fragment, useEffect, useState } from "react";
import { FormType } from "../../utils/constants";
import { Button, Grid, Icon } from "semantic-ui-react";
import AnnouncementCreateEditForm from "../../forms/AnnouncementCreateEditForm";

const AnnouncementCreateEdit = ({
	announcement,
	formType,
	onBackClick,
	submit
}) => {
	return (
		<div>
			<div className="argus-title-background mb-5">
				<div className="argus-title">
					{`${formType === FormType.Create ? "Create" : "Edit"} Announcement`}
				</div>
			</div>
			<Grid container>
				<Grid.Row verticalAlign="middle" textAlign="center">
					{formType === FormType.Edit && !!announcement &&
						<AnnouncementCreateEditForm formType={formType}
													announcement={announcement}
													goBack={onBackClick}
													submit={submit} />
					}
					{formType === FormType.Create &&
						<AnnouncementCreateEditForm formType={formType}
													goBack={onBackClick}
													submit={submit} />
					}
				</Grid.Row>
			</Grid>
		</div>
	)
}

export default AnnouncementCreateEdit;