import moment from 'moment';

export const SouthAfricanRand = new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
});

export const CreateUUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
}



export const customDateFormat = date => {
    const now = moment();
    const yesterday = moment().subtract(1, 'days');
    const lastWeek = moment().startOf('week');

    if (date.isSame(now, 'day')) {
        return date.format('Today at HH:mm');
    } else if (date.isSame(yesterday, 'day')) {
        return date.format('Yesterday at HH:mm');
    } else if (date.isAfter(lastWeek)) {
        return date.format('dddd [at] HH:mm');
    } else if (date.isSame(now, 'year')) {
        return date.format('DD MMM [at] HH:mm');
    } else {
        return date.format('DD MMM YYYY [at] HH:mm');
    }
}

// Usage examples:
console.log(customDateFormat(moment())); // Today
console.log(customDateFormat(moment().subtract(1, 'days'))); // Yesterday
console.log(customDateFormat(moment().day(3))); // Last Wednesday
console.log(customDateFormat(moment('2022-04-17T13:52:00'))); // Different year
