import React, {Fragment, useState} from "react";
import Login from "../../components/Login";
import {Backdrop, CircularProgress} from "@mui/material";
import SiteHeader from "../../components/SiteHeader";
import { isLoggedIn } from "../../api/authentication";
import { LoggedInStatus } from "../../utils/constants";

const checkIsLoggedIn = async ({
	setLoggedIn
}) => {
	try {
		const response = await isLoggedIn();
		setLoggedIn(response);
	}
	catch (e) {
		console.error(e);
		setLoggedIn(LoggedInStatus.No);
	}
}

const LoginPage = () => {
    const [loading, setLoading] = useState(false);

    return (
        <Fragment>
            <SiteHeader />
            <Login setLoading={setLoading} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Fragment>
    );
};
export default LoginPage;
