import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import "./style.css";
import {Button, Container, Form, Grid, Header, Image, Message, Segment} from "semantic-ui-react";
import {authenticate} from "../../api/authenticationController";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {ErrorMessage} from "@hookform/error-message";
import ArgusLogo from "../../img/logo.svg";
import { ApiCallResult } from "../../utils/constants";

const createLoginData = async ({
  email,
  password,
  setLoading,
  setLoginResult,
  setLoginResultMessage
}) => {
  setLoading(true);
  try {
    const loginDataOptions = {
      email,
      password
    };

    let response = await authenticate(loginDataOptions);

    if (!!response?.success) {
      setLoginResult(ApiCallResult.Success);
    }
    else {
      setLoginResult(ApiCallResult.Error);
      setLoginResultMessage("Could not log you in. Please try again later or contact support.");
    }
  } catch (error) {
    setLoginResult(ApiCallResult.Error);
    setLoginResultMessage("Could not log you in. Please try again later or contact support.");
  } finally {
    setLoading(false);
  }
}

const Login = ({setLoading}) => {
  const navigate = useNavigate();
  const schema = yup.object({
    email: yup.string().email().required("Email is required"),
    password: yup.string().required("Password is required")
  }).required();
  const { control, handleSubmit, watch, formState: { errors }, clearErrors } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: ""
    }
  });
  const [loginResult, setLoginResult] = useState(ApiCallResult.NoResult);
  const [loginResultMessage, setLoginResultMessage] = useState("");

  useEffect(() => {
    if (loginResult === ApiCallResult.Success) {
      const timer = setTimeout(() => {
        navigate("/");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [loginResult]);

  const onSubmit = async values => {
    const createLoginDataOptions = {
      email: values.email,
      password: values.password,
      setLoading,
      setLoginResult,
      setLoginResultMessage
    }

    await createLoginData(createLoginDataOptions);
  }

  const resetLoginResult = () => {
    setLoginResult(ApiCallResult.NoResult);
    setLoginResultMessage("");
  };

  return (
    <Container className="argus-full-page-container login-background" fluid>
      <Grid centered padded stretched verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={16}>
            <Image src={ArgusLogo} size="medium" centered as={Link} to="/"/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} />
          <Grid.Column width={8} textAlign="center">
            <Segment padded>
              {loginResult !== ApiCallResult.Success &&
                  <Form onSubmit={handleSubmit(onSubmit)} error size="small">
                    <Grid padded>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Header as="h1">Login to your account</Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={2}/>
                        <Grid.Column width={12}>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column width={16}>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <Form.Input
                                            type="email"
                                            onChange={onChange}
                                            value={value}
                                            label="Email"
                                            placeholder="johndoe@yourdomain.com"/>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="email"
                                    render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
                                />
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                              <Grid.Column width={16}>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <Form.Input
                                            type="password"
                                            onChange={onChange}
                                            value={value}
                                            placeholder="Password"
                                            label="Password"/>
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="password"
                                    render={({message}) => <p className="b4 color-red mb-2">{message}</p>}
                                />
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                              <Grid.Column width={16}>
                                <Button className="blue-button"
                                        type="submit">
                                  Login
                                </Button>
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                              <Grid.Column width={16}>
                                <Link to="/forgot-password">Forgot password?</Link>
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                              <Grid.Column width={16}>
                                <span className="b2">Don't have an account? </span>
                                <Link to="/register">Register</Link>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                        <Grid.Column width={2}/>
                      </Grid.Row>
                      {loginResult === ApiCallResult.Error &&
                          <Grid.Row>
                            <Grid.Column width={16}>
                              <Message
                                  error
                                  content={loginResultMessage}
                                  onDismiss={resetLoginResult}
                              />
                            </Grid.Column>
                          </Grid.Row>
                      }
                    </Grid>
                  </Form>
              }
              {loginResult === ApiCallResult.Success &&
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <p className="b1">Logging you in...</p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
              }
            </Segment>
          </Grid.Column>
          <Grid.Column width={4} />
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default Login;
