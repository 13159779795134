import React, { Fragment } from "react";

import SiteHeader from "../../components/SiteHeader";

const PageLayout = (props) => {

    return (
        <Fragment>
            <main className="argus-main">
                {props.children}
            </main>
        </Fragment>
    );
};

export default PageLayout;