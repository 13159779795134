import React, { Fragment, useEffect, useState } from "react";
import { isLoggedIn, removeAuthCookies } from "../../api/authentication";
import { useNavigate } from "react-router-dom";
import { ApiCallResult, PageStatus } from "../../utils/constants";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";

import { forgotPassword } from "../../api/authenticationController";
import ForgotPassword from "../../components/ForgotPassword";

const checkIsLoggedIn = async ({
	setLoggedIn
}) => {
	try {
		const response = await isLoggedIn();
		setLoggedIn(response);
	}
	catch (e) {
		console.error(e);
	}
}

const clearCookies = async () => {
	await removeAuthCookies();
};

const createForgotPasswordData = async ({
	email,
	setPageStatus,
	setCreateForgotPasswordDataResult
}) => {
	setPageStatus(PageStatus.DataFetchStarted)
	try {
		const forgotPasswordEmailOptions = {
			email: encodeURI(email)
		}

		const response = await forgotPassword(forgotPasswordEmailOptions)

		if (response.success) {
			setCreateForgotPasswordDataResult(ApiCallResult.Success);
		}
		else {
			setCreateForgotPasswordDataResult(ApiCallResult.Failed);
		}
	}
	catch (error) {
		console.error(error);
		setCreateForgotPasswordDataResult(ApiCallResult.Error);
	}
	finally {
		setPageStatus(PageStatus.DataFetchComplete);
	}
}

const ForgotPasswordPage = () => {
	const navigate = useNavigate();
	const [pageStatus, setPageStatus] = useState(PageStatus.NotLoaded);
	const [loggedIn, setLoggedIn] = useState(null);
	const [createForgotPasswordDataResult, setCreateForgotPasswordDataResult] = useState(ApiCallResult.NoResult);

	useEffect(() => {
		const performLoggedInCheck = async () => {
			const checkIsLoggedInOptions = {
				setLoggedIn,
				setPageStatus
			}
			await checkIsLoggedIn(checkIsLoggedInOptions);
		}

		performLoggedInCheck();
	}, []);

	useEffect(() => {
		const clearCookiesAsync = async () => {
			await clearCookies();
		}

		if (loggedIn === "true") {
			navigate("/");
		}
		else if (loggedIn === "false") {
			clearCookiesAsync();
			setPageStatus(PageStatus.DataFetchComplete);
		}
	}, [loggedIn]);

	const onResetClick = () => {
		navigate(0)
	}

	const onSubmit = async ({ email }) => {
		const createForgotPasswordDataAsync = async (email) => {
			const createForgotPasswordDataOptions = {
				email,
				setPageStatus,
				setCreateForgotPasswordDataResult
			}

			await createForgotPasswordData(createForgotPasswordDataOptions);
		}

		createForgotPasswordDataAsync(email);
	}

	const pageStatusLoading =
		pageStatus !== PageStatus.AuthChecksFailed &&
		pageStatus !== PageStatus.DataFetchFailed &&
		pageStatus !== PageStatus.DataFetchComplete

	return (
		<Fragment>
			<SiteHeader/>
			<ForgotPassword onSubmit={onSubmit}
							onResetClick={onResetClick}
							createForgotPasswordDataResult={createForgotPasswordDataResult} />
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageStatusLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
}

export default ForgotPasswordPage;