import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { ApiCallStatus, LoggedInStatus } from "../../utils/constants";
import { cloneDeep } from "lodash";
import {
	fetchAnnouncementsData,
} from "../PageFunctions/announcementFunctions";
import {
	checkIsLoggedIn,
	clearCookiesAsync,
	fetchCurrentUserData
} from "../PageFunctions/commonFunctions";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import Announcements from "../../components/Announcements";

const ModalName = Object.freeze({
	AnnouncementActivation: Symbol(1),
	AnnouncementDeactivation: Symbol(2)
});

const AnnouncementsPage = ({}) => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
	const [currentUser, setCurrentUser] = useState(null);
	const [announcements, setAnnouncements] = useState([]);

	const [announcementToEdit, setAnnouncementToEdit] = useState(null);

	const [openModal, setOpenModal] = useState(null);

	const [pageNumber, setPageNumber] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const [pageSize, setPageSize] = useState(18);

	const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchAnnouncementsDataCallStatus, setFetchAnnouncementsDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const gotoCreatePage = () => navigate("/announcement/create");
	const reloadPage = () => navigate(0);

	const performLoggedInCheck = async () => {
		await checkIsLoggedIn({
			setLoggedIn
		});
	}

	const fetchCurrentUserAsync = async () => {
		await fetchCurrentUserData({
			setCurrentUser,
			setCurrentUserCallStatus
		});
	}

	const fetchAnnouncementsDataAsync = async (pageNumberParam, pageSizeParam) => {
		await fetchAnnouncementsData({
			pageNumber: pageNumberParam ?? "1",
			pageSize: pageSizeParam ?? "18",
			setFetchAnnouncementsDataCallStatus,
			setAnnouncements,
			setTotalResults
		});
	}

	const onPageNumberChange = async (_, {activePage}) => {
		await updateUrlQueryValue("pageNumber", activePage);
	};

	const updateUrlQueryValue = async (key, value) => {
		setFetchAnnouncementsDataCallStatus(ApiCallStatus.NotStarted);
		const currentParams = Object.fromEntries([...searchParams]);
		const paramsClone = cloneDeep(currentParams);

		paramsClone[key] = value;
		await navigate({
			pathname: '/announcements',
			search: `?${createSearchParams(paramsClone)}`
		});
	}

	const authorizedPageLoad = async () => {
		const pageNumberParam = searchParams.get('pageNumber') ?? "1";
		const pageSizeParam = "18";

		await Promise.all([
			fetchCurrentUserAsync(),
			fetchAnnouncementsDataAsync(pageNumberParam, pageSizeParam)
		]);
	}

	const openAnnouncementActivationModal = () => {
		setOpenModal(ModalName.AnnouncementActivation)
	}

	const openAnnouncementDeactivationModal = () => {
		setOpenModal(ModalName.AnnouncementDeactivation)
	}

	const closeModals = () => {
		setOpenModal(null);
		navigate(0);
	}

	useEffect(() => {
		performLoggedInCheck();
	}, []);

	useEffect(() => {
		fetchAnnouncementsDataAsync(pageNumber)
	}, [pageNumber]);

	useEffect(() => {
		if (loggedIn === LoggedInStatus.Yes) {
			authorizedPageLoad();
		} else if (loggedIn === LoggedInStatus.Refreshed) {
			reloadPage();
		} else if (loggedIn === LoggedInStatus.No) {
			clearCookiesAsync();
			gotoHomePage();
		}
	}, [loggedIn]);

	const allLoadingStates = [
		currentUserCallStatus,
		fetchAnnouncementsDataCallStatus
	];

	const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

	return (
		<Fragment>
			<SiteHeader currentUser={currentUser}/>
			<Announcements
				announcements={announcements}
				totalResults={totalResults}
				pageNumber={pageNumber}
				pageSize={pageSize}
	            onPageNumberChange={onPageNumberChange}
		        onBackClick={gotoHomePage}
				showAnnouncementActivationModal={openModal === ModalName.AnnouncementActivation}
				showAnnouncementDeactivationModal={openModal === ModalName.AnnouncementDeactivation}
				openAnnouncementActivationModal={openAnnouncementActivationModal}
				openAnnouncementDeactivationModal={openAnnouncementDeactivationModal}
				closeModals={closeModals}
				announcementToEdit={announcementToEdit}
				setAnnouncementToEdit={setAnnouncementToEdit}
				gotoCreatePage={gotoCreatePage}
			/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={isLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
}

export default AnnouncementsPage;