import React, {useEffect} from "react";
import {logout} from "../../api/authenticationController";
import {useNavigate} from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        logout();
        navigate("/");
    }, [])

    return (
        <div>
            <p>Please wait...</p>
        </div>
    );
}

export default Logout;