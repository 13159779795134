import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiCallStatus, FormType, LoggedInStatus } from "../../utils/constants";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import {
    checkIsLoggedIn,
    clearCookies, fetchCountryData,
    fetchCurrentUserData, fetchProvinceData, fetchSalesPeopleData
} from "../PageFunctions/commonFunctions";
import {
    fetchDetailedUserData,
} from "../PageFunctions/userFunctions";
import UserCreateEdit from "../../components/UserCreateEdit";
import {
    fetchOrdersDataByUserId,
    fetchUserAddressesData,
    getOrdersDataByUserId
} from "../PageFunctions/orderFunctions";

const UserCreateEditPage = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(LoggedInStatus.NotChecked);
    const [currentUser, setCurrentUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [orderData, setOrderData] = useState(null);
    const [addressesData, setAddressesData] = useState(null);
    const [countryData, setCountryData] = useState(null);
    const [provinceData, setProvinceData] = useState(null);
    const [salesPeopleData, setSalesPeopleData] = useState(null);

    const [currentUserCallStatus, setCurrentUserCallStatus] = useState(ApiCallStatus.NotStarted);
    const [fetchUserDataCallStatus, setFetchUserDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [fetchOrderDataCallStatus, setFetchOrderDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [fetchSalesPeopleDataCallStatus, setFetchSalesPeopleDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [fetchAddressesDataCallStatus, setFetchAddressesDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [fetchProvinceDataCallStatus, setFetchProvinceDataCallStatus] = useState(ApiCallStatus.NotStarted);
    const [fetchCountryDataCallStatus, setFetchCountryDataCallStatus] = useState(ApiCallStatus.NotStarted);

    const gotoHomePage = () => navigate("/");
    const gotoUsersPage = () => navigate("/users");
    const reloadPage = () => navigate(0);

    const performLoggedInCheck = async () => {
        await checkIsLoggedIn({
            setLoggedIn
        });
    }

    const clearCookiesAsync = async () => {
        await clearCookies();
    }

    const fetchCurrentUserAsync = async () => {
        await fetchCurrentUserData({
            setCurrentUser,
            setCurrentUserCallStatus
        });
    }

    const fetchUserDataAsync = async () => {
        if (!!userId) {
            await fetchDetailedUserData({
                userId,
                setUser: setUserData,
                setStatusInProgress: () => setFetchUserDataCallStatus(ApiCallStatus.InProgress),
                setStatusSuccess: () => setFetchUserDataCallStatus(ApiCallStatus.Succeeded),
                setStatusFailed: () => setFetchUserDataCallStatus(ApiCallStatus.Failed),
                setStatusError: () => setFetchUserDataCallStatus(ApiCallStatus.Error),

            });
        }
    }

    const fetchSalesPeopleDataAsync = async () => {
        if (!!userId) {
            await fetchSalesPeopleData({
                setSalesPeople: setSalesPeopleData,
                setStatusInProgress: () => setFetchSalesPeopleDataCallStatus(ApiCallStatus.InProgress),
                setStatusSuccess: () => setFetchSalesPeopleDataCallStatus(ApiCallStatus.Succeeded),
                setStatusFailed: () => setFetchSalesPeopleDataCallStatus(ApiCallStatus.Failed),
                setStatusError: () => setFetchSalesPeopleDataCallStatus(ApiCallStatus.Error),

            });
        }
    }

    const fetchCountryDataAsync = async () => {
        if (!!userId) {
            await fetchCountryData({
                setCountries: setCountryData,
                setStatusInProgress: () => setFetchCountryDataCallStatus(ApiCallStatus.InProgress),
                setStatusSuccess: () => setFetchCountryDataCallStatus(ApiCallStatus.Succeeded),
                setStatusFailed: () => setFetchCountryDataCallStatus(ApiCallStatus.Failed),
                setStatusError: () => setFetchCountryDataCallStatus(ApiCallStatus.Error),

            });
        }
    }

    const fetchProvinceDataAsync = async () => {
        if (!!userId) {
            await fetchProvinceData({
                setProvinces: setProvinceData,
                setStatusInProgress: () => setFetchProvinceDataCallStatus(ApiCallStatus.InProgress),
                setStatusSuccess: () => setFetchProvinceDataCallStatus(ApiCallStatus.Succeeded),
                setStatusFailed: () => setFetchProvinceDataCallStatus(ApiCallStatus.Failed),
                setStatusError: () => setFetchProvinceDataCallStatus(ApiCallStatus.Error),

            });
        }
    }

    const fetchAddressDataAsync = async () => {
        if (!!userId) {
            await fetchUserAddressesData({
                userId,
                setAddresses: setAddressesData,
                setStatusInProgress: () => setFetchAddressesDataCallStatus(ApiCallStatus.InProgress),
                setStatusSuccess: () => setFetchAddressesDataCallStatus(ApiCallStatus.Succeeded),
                setStatusFailed: () => setFetchAddressesDataCallStatus(ApiCallStatus.Failed),
                setStatusError: () => setFetchAddressesDataCallStatus(ApiCallStatus.Error),

            });
        }
    }

    const fetchUserOrderDataAsync = async () => {
        if (!!userId) {
            await fetchOrdersDataByUserId({
                userId,
                setOrders: setOrderData,
                setStatusInProgress: () => setFetchOrderDataCallStatus(ApiCallStatus.InProgress),
                setStatusSuccess: () => setFetchOrderDataCallStatus(ApiCallStatus.Succeeded),
                setStatusFailed: () => setFetchOrderDataCallStatus(ApiCallStatus.Failed),
                setStatusError: () => setFetchOrderDataCallStatus(ApiCallStatus.Error),

            });
        }
    }

    const authorizedPageLoad = async () => {
        await Promise.all([
            fetchCurrentUserAsync(),
            fetchUserDataAsync(),
            fetchUserOrderDataAsync(),
            fetchSalesPeopleDataAsync(),
            fetchAddressDataAsync(),
            fetchProvinceDataAsync(),
            fetchCountryDataAsync()
        ]);
    }

    useEffect(() => {
        performLoggedInCheck();
    }, []);

    useEffect(() => {
        if (loggedIn === LoggedInStatus.Yes) {
            authorizedPageLoad();
        } else if (loggedIn === LoggedInStatus.Refreshed) {
            reloadPage();
        } else if (loggedIn === LoggedInStatus.No) {
            clearCookiesAsync();
            gotoHomePage();
        }
    }, [loggedIn]);

    const allLoadingStates = [
        currentUserCallStatus,
        fetchUserDataCallStatus,
        fetchOrderDataCallStatus,
        fetchSalesPeopleDataCallStatus,
        fetchAddressesDataCallStatus,
        fetchProvinceDataCallStatus,
        fetchCountryDataCallStatus
    ];

    const isLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

    return (
        <Fragment>
            <SiteHeader currentUser={currentUser}/>
            <UserCreateEdit
                formType={!!userId ? FormType.Edit : FormType.Create}
                onBackClick={gotoUsersPage}
                user={userData}
                orders={orderData}
                addresses={addressesData}
                provinces={provinceData}
                countries={countryData}
                salesPeople={salesPeopleData}/>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </Fragment>
    );
};
export default UserCreateEditPage;